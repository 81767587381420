@import 'Funnel/styles/Common.scss';

.help-button-container {
  position: fixed;
  bottom: 18px;
  z-index: 99999;
  right: 30px;

  .circle-button {
    @include flex-vertical-center-column;
  }

  .circle-button,
  .circle-button:hover,
  .circle-button:active,
  .circle-button:focus,
  .circle-button.active {
    background: $primary-gradient;
    color: $color-high-contrast !important;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 35px;
    border: none !important;

    @include breakpoint(max_sm) {
      width: 50px;
      height: 50px;
      font-size: 25px;
    }
  }

  .circle-button:hover {
    transform: scale(1.03);
  }

  .circle-button {
    svg {
      vertical-align: middle;
      transform: translate(0, 1px);
    }
  }

  @include breakpoint(max_sm) {
    right: 15px;
  }
}

.menu-conatiner {
  @extend .menu-conatiner;
}

.smooth-transition {
  transition: ease-in-out 0.4s;
}

.left-align-help-btn {
  margin-right: 59px !important;
  transition: all 0.4s;
}
