.ad-builder-preview {
  max-width: 800px;

  [data-cy='root-container'] {
    margin-top: 50px !important;
  }

  .page-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ad-builder-empty-page {
    width: 100%;
    img {
      position: relative;
      top: 20px;
    }
    h2 {
      font-weight: 500;
    }
    p {
      color: rgb(0 0 0 / 65%);
      font-weight: 300;
      line-height: 1.3em;
      font-size: 1.3em;
      width: 800px;
    }
    .ant-typography {
      font-size: 32px;
      font-weight: 600;
    }
    .empty-page-list--container {
      transform: none !important;
    }
  }

  .hidden-container {
    display: flex;
    justify-content: center;
    padding: 10px;
    svg {
      color: $primary-color;
    }
  }

  [data-cy='root-container'] {
    & > .actions-controller__wrapper {
      margin-bottom: 3rem;
    }
  }

  .ad-type__quote:before {
    content: '‟';
    font-size: 390px;
    line-height: 0;
    position: absolute;
    left: 0;
    top: 80px;
    color: #fff;
    opacity: 0.4;
    font-style: italic;
  }

  .post-preview {
    background: #fff;
    padding-top: 30px !important;
    padding-bottom: 0 !important;
    box-shadow: 2px 4px 10px rgb(0 0 0 / 10%);
    border-radius: 12px;
    border: 1px solid #e9e9e9;
    flex-direction: column;
    z-index: 0;
    & > .rich-text__wrapper {
      margin-bottom: 1.25em;
      margin-inline: 30px !important;
    }
    & > .profile-section {
      margin-inline: 30px !important;
    }

    .footer-wrapper {
      .plain-text {
        &__wrapper {
          margin-top: 0.4em;
          margin-bottom: 0.4em;
        }

        &-editor {
          p {
            font-weight: 600;
            margin-bottom: 0px;
            font-size: 1.25em;
            line-height: 1.25em;
            color: #333;
          }
        }
      }
    }
  }

  .media-content-wrapper {
    width: 100%;
    height: 100%;

    .action-position {
      &-top {
        top: -32px !important;
      }
    }

    &,
    .actions-controller__wrapper {
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      .img-element__wrapper {
        padding: 0;

        img {
          height: 800px;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .content-wrapper {
      padding: 0px;
    }
  }

  .footer-wrapper {
  }

  .container__padding {
    &-s-top,
    &-s-bottom {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.ad-type-card {
  width: 100%;
  display: flex !important;
  border: 2px solid #efefef;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: red;
}
