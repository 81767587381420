
.support-card-component {
  .text-component-wrapper {
    height: 140px;
    overflow: auto;
  }
  ::-webkit-scrollbar {
    border-radius: 15px;
    width: 0px;
    background: transparent !important;
    display: none;
  }
}

.sortable-link-card {
  #contact-icons-listing {
    z-index: 10;
  }

  .contact-link-card {
    .card-title-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .add-link-button {
    margin-top: 20px;
  }
}

.ant-collapse-header {
  padding-inline: 0px !important;
  background: $color-high-contrast;
}

.ant-collapse-content {
  background: $color-high-contrast;
}

.ant-collapse-content-box {
  padding-inline: 0px !important;
}

.color-picker-row {
  margin: 1.5em 0;
}

.drag-handler {
  cursor: move;
}
