label {
    .ant-form-item {
        margin: -4px 0 0 10px;
    }
}

.ant-form {
    label {
        font-size: 14px;
        font-weight: 400;
        color: $primary-text-color;
        margin-bottom: 0.85em;
        display: block;
    }
}

.fields-inline {
    display: flex !important;

    .ant-form-item {
        margin-right: 15px !important;
        width: 100%;
        flex: 1;

        &:last-child {
            margin-right: 0 !important;
        }
    }
}
