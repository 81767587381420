.empty-page-list--container {
  transform: translateY(-90px);
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preview-section {
  .empty-page-list--container {
    transform: translateY(-25px);
  }
}

.settings-missing-overlay {
  .empty-page-list--container {
    transform: none !important;
  }
}

.page-list {
  height: 100%;
  .sortable-container {
    z-index: 10;
  }
}

.page-name__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 0.25em;
  padding-top: 0.1em;

  &,
  &:hover {
    transition: ease color 0.5s;
  }

  &:hover {
    .page-name {
      color: $secondary-color;
    }
    .page-number {
      color: $secondary-color;
    }
  }

  &.deactive {
    .page-name {
      cursor: not-allowed !important;
      color: $secondary-text-color;
      opacity: 0.5;
    }

    .icon-btn {
      cursor: not-allowed !important;
      color: $secondary-text-color !important;
    }
  }

  &.active {
    .page-name {
      font-weight: 600;
      color: $primary-color;
    }
    .page-number {
      font-weight: 600;
      color: $primary-color;
    }
  }

  .drag-handle {
    font-size: 18px;
    padding-right: 10px;
    cursor: move;
    color: $primary-color;
    opacity: 0.2;
  }

  .page-name {
    font-size: 15px;
    width: 100%;
    margin: 0;
    align-self: baseline;

    p {
      margin-bottom: 0;
      max-width: 336px;
      overflow: hidden;
    }
  }

  .icon-btn {
    margin: 0;
  }

  .ant-dropdown-trigger {
    &.icon-btn {
      color: $primary-color;
      opacity: 0.5;
    }
  }
}

.sortable-helper {
  z-index: 10;
}

.code-editor--wrapper {
  .code-editor {
    border: 1px solid $form-border-grey;
  }
}

.page-number {
  height: 100%;
  align-self: baseline;
  margin-right: 5px;
  color: #777;
  font-weight: 300;
}

.dropable-area-animate {
  animation: backgroundColorTransition 4s ease-in infinite;
}

.deactive {
  cursor: not-allowed !important;
  color: $secondary-text-color;
  opacity: 0.5;
}

@keyframes backgroundColorTransition {
  0% {
    background-color: rgba(78, 204, 78, 0.15);
    border-radius: 0px;
  }

  50% {
    background-color: rgba(78, 204, 78, 0.3);
    border-radius: 10px;
  }

  100% {
    background-color: rgba(78, 204, 78, 0.15);
    border-radius: 0px;
  }
}
