.share {
  .ant-switch {
    margin-right: 0.75em;
  }
}

.booking-button__button-preview {
  display: block;
  font-family: sans-serif;
  background: $primary-gradient;
  color: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);

  width: 100%;
  padding: 0.675em 1.75em;
  font-weight: 500;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 5em;
  -webkit-tap-highlight-color: transparent;
}

.booking-button__inner .ant-tag {
  margin-bottom: 8px;
}

.copy-input {
  .ant-input {
    background-color: #fff !important;

    &[disabled] {
      color: $primary-color;
    }
  }

  .ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        column-gap: 4px;
      }
    }
  }
}
