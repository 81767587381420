.checkout {
  .ant-layout {
    height: initial;
  }

  &__header {
    &-image {
      width: 100%;
      margin-bottom: 50px;
      border-radius: $default-border-radius;
    }

    p {
      max-width: 750px;
    }
  }

  &__step-packages {
    & > .ant-space-item:first-child {
      margin-bottom: 0 !important;
    }
  }

  &__step-packages {
    .checkout__header {
      text-align: center;

      p {
        margin: 0 auto;
      }
    }
  }

  &__progress-steps {
    max-width: 1000px;
    margin: 0 auto !important;
  }

  &__step-with-package-info {
    & > .ant-col:last-child {
      padding-left: 30px;
    }

    &__image {
      width: 100%;
      max-width: 120px;
      margin: 0 auto 25px;
      display: table;
    }

    &__features {
      color: $secondary-text-color;
      font-weight: 300;

      .anticon {
        color: $green;
      }

      .package-features-item {
        margin: 0.5em 0;

        &__icon {
          margin-right: 0.5em;
        }
      }
    }

    &__price {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;

      & > * {
        flex: 1;
      }

      &-left {
        h2,
        h3 {
          margin-bottom: 0;
          line-height: 1;
        }

        .small-text {
          color: $secondary-text-color;
        }
      }

      &-right {
        text-align: right;
        font-size: 1.75em;
        font-weight: 300;

        &-small {
          font-size: 1.17em;
          line-height: 0;
        }
      }
    }
  }

  .ant-space {
    display: flex !important;
  }

  &__thank-you {
    text-align: center;

    img {
      max-width: 85px;
      margin-bottom: 1em;
    }

    button {
      margin-top: 1em;
    }
  }

  &__footer {
    text-align: center;

    [target="_blank"].ant-btn {
      span {
        color: $secondary-text-color !important;
      }
    }
  }

  &__content-wrapper {
    padding: 65px 10px;
  }

  .horizontal-list-of-logos {
    margin: 10px 0 30px;
  }

  .switch-left-right-labels {
    margin: 0;
  }

  .support-info {
    padding-right: 0;
  }

  .ui__logo {
    margin: 0;
    padding: 0;
  }

  .ant-steps-item-finish {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    &__progress-steps {
      max-width: 400px;
    }

    &__content-wrapper {
      padding: 40px 10px;
    }
  }

  @include breakpoint(max_md) {
    &__content-wrapper {
      padding: 35px 10px;
    }

    &__header {
      h1 {
        font-size: 1.85em !important;
      }

      .ant-typography:last-child {
        margin-bottom: 0;
      }

      &-image {
        margin-bottom: 20px;
      }
    }

    &__thank-you {
      h2 {
        font-size: 1.85em !important;
      }
    }

    &__step-with-package-info > .ant-col:last-child {
      padding-left: 0;
    }

    & .ui__sub-header-background {
      line-height: initial !important;
      padding: 15px !important;
      height: initial;
      display: initial;
    }

    & .ui__logo {
      margin: 0;
      width: initial !important;
    }

    &__progress-steps {
      margin: 25px 0 10px !important;
    }

    .support-info {
      flex-direction: initial;
      justify-content: initial;

      &__label {
        margin-right: 0.5em;
      }
    }
  }

  .ant-alert {
    margin-bottom: 0 !important;
  }
}

.money-back-badge {
  background: $light-grey-medium;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px;

  & > * {
  }

  &__left {
    margin-right: 12px;

    .anticon {
      font-size: 4em;
      color: $primary-color;
    }
  }

  &__right {
    h2 {
      margin-bottom: 0;
      line-height: 1;
    }
  }
}

.review-slider {
  &__card {
    margin-bottom: 1em !important;

    &__stars {
      width: 100px;
    }

    q {
      display: block;
      margin: 1em 0;
      font-style: italic;
    }

    .ant-avatar-image {
      margin-right: 10px;
    }
  }

  &__trustpilot-logo {
    margin: 0 auto;
    display: table;
    width: 150px;
  }
}

.paddle-inline-checkout {
  position: relative;

  .white-overlay {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
