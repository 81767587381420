$check: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke="%23333333" fill="%23333333" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em"><path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/></svg>');

.welcome-page-builder {
    .ant-tabs-bar {
        border-color: $default-grey !important;
        margin: 0 0 25px 0 !important;
    }

    .ant-tabs-tab {
        background: #fff !important;
        border: 1px solid $default-grey !important;

        &-active {
            background: initial !important;
            border-color: $default-grey !important;
            padding: 8px 25px !important;
            border-bottom: 1px solid $light-grey !important;
        }
    }

    .ant-tabs-nav-wrap {
        min-height: 35px;
    }

    .rdw-editor-main {
        text-align: center;
        max-height: 500px;
    }

    .public-DraftStyleDefault-block {
        text-align: center;
    }

    .public-DraftStyleDefault-ul {
        li {
            list-style: none;
            text-align: left;
            margin-top: 0.45em;
            display: flex;

            &:before {
                display: inline-block;
                content: " ";
                margin-right: 7px;
                margin-top: 4px;
                width: 15px;
                height: 15px;
                background: $check;
                background-repeat: no-repeat;
                background-size: 100%;
                flex: none;
                align-items: center;
            }

            .public-DraftStyleDefault-block {
                margin: 0 !important;
            }
        }

        .public-DraftStyleDefault-block {
            // text-align: left !important;
        }
    }

    &__form {
        padding-right: 30px;

        &-content,
        & .ant-steps {
            padding: 15px;
            background: #fff;
        }

        & .ant-steps {
            border-bottom: 1px solid $light-grey;
        }
    }

    label.ant-checkbox-wrapper {
        margin-bottom: 0 !important;
    }
}

.testimonials {
    .ant-form-item:last-child {
        margin-bottom: 0 !important;
    }
}

.safari {
    .rdw-editor-main,
    .public-DraftStyleDefault-block {
        text-align: left;
    }

    .public-DraftStyleDefault-ul {
        li {
            margin-left: 0 !important;

            .public-DraftStyleDefault-block {
                text-align: left;
            }
        }
    }
}
