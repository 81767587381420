.account-info {
  &__card {
    height: calc(100% - 24px);

    .ant-tag {
      font-size: 1.05em;
      padding: 0.25em 0.5em;
      font-weight: 300;
    }
  }

  &__documents {
    margin-top: 24px;

    &-inner {
      margin: 30px 0 24px;
    }
  }

  &__contingent {
    h3 {
      margin-bottom: 0 !important;
      margin-top: 0.675em !important;

      &:first-child {
        margin-top: 0 !important;
      }

      .anticon-info-circle.default-popover {
        font-size: 0.85em;
      }
    }

    .ant-progress-show-info .ant-progress-outer {
      margin-right: 0 !important;
      padding-right: 0 !important;
    }

    .ant-progress-text {
      margin-left: 0 !important;
      width: 100%;
    }

    .ant-progress-status-success .ant-progress-bg {
      background-color: $red !important;
    }

    .ant-progress-status-success .ant-progress-text {
      color: $red !important;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-size: 1.15em;
    padding: 5px 0 10px !important;
  }

  .open-payment-modal,
  .account-update-modal__trigger .ant-btn {
    padding: 0 !important;
  }

  a svg {
    margin-right: 0;
  }
}
