.ad-suggestion__container {
  .ant-collapse-content-box {
    max-height: 500px;
    overflow-y: auto;
  }

  .builder__settings-sidebar__row {
    margin-top: 0;
  }

  .ant-collapse {
    border: none;
    background: none !important;

    &-content-box {
      padding: 0;
    }

    &-header {
      padding: 1rem 3rem !important;

      .ant-collapse-arrow {
        left: 1.5rem;
      }

      &,
      &:hover {
        transition: ease all 0.5s;
      }

      &:hover {
        padding-left: 3.25rem !important;
      }
    }
  }
}
