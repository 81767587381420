.rich-text__wrapper {
  [style*="font-size: 3.5em;"],
  [style*="font-size: 2.5em;"],
  [style*="font-size: 1.5em;"] {
    line-height: 1.15em;
  }
  
  .public-DraftStyleDefault-block {
    margin: 0;
    margin-bottom: 0.8em;
  }

  .public-DraftEditor-content > div > div:last-child .public-DraftStyleDefault-block {
    margin-bottom: 0;
  }
}

.public-DraftStyleDefault-block {
  margin: 0;
}