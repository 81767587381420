@import 'Builder/styles/ui/Common.scss';

.builder__settings__image-select-modal {
  .ant-tag {
    margin-bottom: 8px;
  }

  &__template-tags {
    @extend .template-tags;
  }
  .ant-modal-body{
    padding: 0px 24px 24px 24px;
  }
}
