.widget-preview-container {
  background-color: $light-border-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem 0;

  .widget-main-text {
    width: 100%;

    .rich-text__wrapper {
      padding: 1rem;
    }
  }
}

.widget-tiles-wrapper {
  background-color: $light-grey;
  width: 100%;
  padding-left: 1.15rem;
  padding-right: 1.15rem;
  margin-top: 1.5rem;

  .widget-empty-view {
    margin: 1rem;
  }
}

.widget-tile-card {
  display: flex;
  border: 1px solid $form-border-grey;
  border-radius: 15px;
  margin: 1rem 0;
  padding: 1.8rem 2rem;
  min-height: 100px;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);

  @include defaultOpacityHover(0.75);

  &:last-child {
    margin-bottom: 0;
  }

  .children {
    width: 98%;
  }

  .widget-job-info {
    display: flex;
    width: 50%;

    .job-attribute {
      display: flex;
      align-items: center;
      column-gap: 6px;
      margin-right: 1.5rem;
      margin-top: 0.15rem;

      &:last-child {
        margin-right: 0;
      }

      span {
        display: flex;
      }
    }
  }

  .right-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.25rem;
  }

  h5 {
    font-weight: 600;
  }
}

.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;

  &-logo {
    height: 60px;
  }

  &-nav-logo {
    height: 40px;
  }

  &-burger-icon {
    height: 40px;
    display: none;
  }
}
