@mixin breakpoint($point) {
  @if ($point == xl) {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if ($point == max_993) {
    @media (max-width: 993px) {
      @content;
    }
  } @else if ($point == max_1280) {
    @media (max-width: 1281px) {
      @content;
    }
  } @else if ($point == lg) {
    @media (min-width: 994px) and (max-width: 1199px) {
      @content;
    }
  } @else if ($point == max_lg) {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if ($point == md) {
    @media (min-width: 768px) and (max-width: 993px) {
      @content;
    }
  } @else if ($point == max_md) {
    @media (max-width: 993px) {
      @content;
    }
  } @else if ($point == min_md) {
    @media (min-width: 994px) {
      @content;
    }
  } @else if ($point == sm) {
    @media (max-width: 767px) and (min-width: 576px) {
      @content;
    }
  } @else if ($point == max_sm) {
    @media (max-width: 768px) {
      @content;
    }
  } @else if ($point == min_sm) {
    @media (min-width: 768px) {
      @content;
    }
  } @else if ($point == xs) {
    @media (max-width: 375px) {
      @content;
    }
  } @else if ($point == max_415) {
    @media (max-width: 415px) {
      @content;
    }
  } @else if ($point == max_390) {
    @media (max-width: 390px) {
      @content;
    }
  } @else if ($point == max_370) {
    @media (max-width: 370px) {
      @content;
    }
  } @else if ($point == max_330) {
    @media (max-width: 330px) {
      @content;
    }
  } @else if ($point == max_667) {
    @media (max-width: 667px) {
      @content;
    }
  } @else if ($point == max_575) {
    @media (max-width: 575px) {
      @content;
    }
  } @else if ($point == min_576) {
    @media (min-width: 576px) {
      @content;
    }
  } @else if ($point == max_1440) {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if ($point == max_440) {
    @media (max-width: 440px) {
      @content;
    }
  } @else if ($point == max_height_600) {
    @media (max-height: 600px) {
      @content;
    }
  } @else if ($point == max_height_700) {
    @media (max-height: 700px) {
      @content;
    }
  } @else if ($point == iphone_x) {
    @media (width: 375px) and (min-height: 730px) and (max-height: 813px) {
      @content;
    }
  } @else if ($point == landscape) {
    @media screen and (orientation: landscape) and (min-width: 500px) and (max-width: 920px) {
      @content;
    }
  }
}

.show-on-max-md {
  display: none;

  @include breakpoint(max_md) {
    display: block;
  }
}

.show-on-min-md {
  display: none;

  @include breakpoint(min_md) {
    display: block;
  }
}

.show-on-max-sm {
  display: none;

  @include breakpoint(max_sm) {
    display: block;
  }
}

.show-on-min-sm {
  display: none;

  @include breakpoint(min_sm) {
    display: block;
  }
}

