.job-cta-container {
  * {
    color: $white !important;
  }
  display: inline-block;
  background: $primary-color;
  padding: 5px 20px;
  border-radius: 30px;

  .actions-controller__wrapper {
    margin: 0px !important;
  }

  div {
    font-weight: 500;
    font-size: large;
  }
}