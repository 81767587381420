.job-company-card {
  &-wrapper {
    display: flex;
  }

  &__address {
    color: $secondary-text-color;
  }

  &__name {
    font-weight: 600;
  }

  &__logo {
    max-width: 75px;
    display: flex;
    padding: 10px 10px 10px 0px;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  &__content {
    margin-left: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .rich-text__wrapper {
      margin: 0px !important;
      padding: 0px !important ;
      width: 360px;
    }
  }
}
