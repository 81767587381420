.blocked-feature-overlay {
    &__overlay-visible {
        position: relative;
        height: 100%;
    }

    &__overlay {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-black-layer {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.02);
            z-index: 1;
            -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
            filter: blur(6px);
        }

        .ant-empty {
            transform: translateY(-8%);
        }
    }
}
