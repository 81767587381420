.sider__bottom {
  .mini-trophy-wrapper {
    padding-right: 15px;
    transition: transform 0.2s ease-in;

    &.mini-trophy-pulse {
      animation-name: trophy-pulse;
      animation-duration: 4s;
      animation-delay: 0;
    }
  }

  &:hover {
    .mini-trophy-wrapper {
      transform: scale(1.2);
    }
  }
}

@keyframes trophy-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.tour-modal {
  .ant-modal-body {
    padding: 0;
  }

  .modal-tage {
    &-reward {
      padding: 4px 10px;
      background-color: $light-green;
      border: 1px solid $border-green;
      color: $green;
      font-weight: 600;
      border-radius: 4px;
    }

    &-ul {
      list-style: none;
      padding: 20px 0 30px;
    }

    &-list-wrapper {
      padding: 0;
      cursor: pointer;

      &:hover {
        background: $light-grey;
      }

      .modal-tage-item {
        flex-direction: row;
        font-size: 1.2em;
        padding: 6px 42px;
      }

      .modal-tage-item-name {
        padding-left: 20px;
      }

      .ant-checkbox-inner,
      .ant-checkbox-input {
        border-radius: 50%;
        background-color: $light-grey-medium;
        border: 1px solid $border-green;
      }

      .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: $green;
      }

      .ant-checkbox-checked::after {
        border: none;
      }

      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: 1px solid $form-border-grey;
      }
      .play-icon {
        position: absolute;
        font-size: 40px;
        top: -0.5px;
        left: 1px;
        color: white;
      }

      .ant-checkbox-input:checked:hover + .ant-checkbox-inner,
      .ant-checkbox-input:checked:focus + .ant-checkbox-inner {
        border: 1px solid $border-green;
      }

      .ant-checkbox-inner {
        padding: 20px;
        text-align: center;

        &:after {
          top: 47%;
          left: 26%;
          width: 14px;
          height: 22px;
        }
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        border-color: $border-green;
        background-color: $light-green;

        &:after {
          border-width: 4px;
        }
      }

      svg.play-icon {
        stroke: $border-green;
        stroke-width: 0.5px;
        stroke-linejoin: round;
        fill: $light-green;

        path:first-child {
          stroke-width: 0;
        }
      }
    }

    &-title-wrapper {
      padding: 20px 18px 20px 18px;
      flex-wrap: nowrap;
      align-items: center;

      .ant-progress-line {
        margin-top: 1em;
      }

      .modal-tage-content-wrapper {
        flex-wrap: nowrap;
        flex-direction: column;
      }
    }

    &-trophy {
      margin-right: 30px;
      width: 145px;
    }

    @include breakpoint(max_md) {
      &-title-wrapper {
        flex-direction: column;
        text-align: center;
      }

      &-item {
        padding: 10px 30px !important;

        .ant-col:first-child {
          max-width: 190px;
        }
      }

      &-trophy {
        margin-bottom: 1em;
        margin-right: 0;
        width: 100px;
      }
    }
  }
}

