div[current-route*='widget-builder'] {
  .meetovo-funnel-builder-layout {
    @import 'elements/index.scss';
  }

  .ant-card {
    box-shadow: none;
  }

  .rich-text__wrapper {
    h1, h2, h3 {
      margin-bottom: 0px;
      strong {
        font-weight: 900;
      }
    }

    p {
      padding: 0px !important;
    }
  }

  .settings-card__no-padding {
    .ant-card-body {
      padding: 0 !important;

      .ant-tabs-nav-wrap {
        padding: 0 24px !important;
      }
    }
  }
}
