.builder__content {
  background-color: $light-grey;
  .container__wrapper {
    min-height: 260px;
    width: 100%;
    .empty-container-layout {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 0 0.75rem;

      .ant-alert {
        margin: 0 !important;
      }

      &__inner {
        max-width: 420px;
        transition: 1s all ease-in-out;
        animation: pulse-opacity 2s ease-in-out 0s infinite normal;

        &:hover {
          animation: none;
        }
      }
    }
  }
  .hidden-container {
    display: flex;
    justify-content: center;
    padding: 10px;
    svg {
      color: $primary-color;
    }
  }
  .container__wrapper:hover,
  .container__add-wrapper:hover,
  .active-actions-controller__wrapper {
    .column-wrapper {
      border: 1px dashed rgb(200, 200, 200) !important;
    }
  }
  .column-grid-container {
    min-height: inherit;
    display: flex;
    flex-direction: row;
    border: none;
    pointer-events: none;
    background-color: inherit;

    .column-wrapper {
      padding: 2px;
      background: inherit;
      pointer-events: auto;
      height: 100%;
      min-height: 40px;
      border: 1px solid transparent;
      z-index: 1;
      position: relative;
    }
  }
}

@keyframes pulse-opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.actions-controller__wrapper {
  position: relative;
}

.actions-controller {
  &__label {
    position: absolute;
    top: -23px;
    left: 0px;
    font-size: 0.9em;
    background: #fff;
    padding: 1px 8px;
    z-index: 1;
    box-shadow: 0px -3px 8px rgb(0 0 0 / 8%);
    border-radius: 10px 10px 0 0;
    border: 1px solid #ddd;
  }
}

.active-actions-controller__wrapper > .actions-controller__label {
  display: flex !important;
}
