.element-dropable-area {
  &__fullsize {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
  }

  &__wrapper {
    &__inner {
      background-color: rgba(78, 204, 78, 0.15);
      animation: backgroundColorTransition 2.5s infinite;
      border: 1px #ccc dotted;
      display: flex;
      justify-content: center;
      align-items: center;

      .anticon {
        font-size: 1.3em;
        color: rgb(49, 219, 46);
      }
    }
  }
}

@keyframes backgroundColorTransition {
  0% {
    background-color: rgba(78, 204, 78, 0.15);

  }
  50% {
    background-color: rgba(78, 204, 78, 0.3);
  }
  100% {
    background-color: rgba(78, 204, 78, 0.15);
  }
}
