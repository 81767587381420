[current-route*='google-job-builder/job'] {
  .job-preview-wrapper {
    svg {
      color: $primary-color !important;
    }
    h5 {
      color: $primary-text-color !important;
    }
  }
}
.job-preview-container {
  border: 1px solid $form-border-grey;
  max-width: 1200px;
  margin: 0 auto;
  background: white;

  .job-preview-wrapper {
    margin: 30px;

    .public-DraftStyleDefault-ltr {
      color: $primary-text-color;
    }

    .rich-text__wrapper {
      margin: 2.5rem 0px 1rem 0px;
    }

    .job-attributes {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;

      .job-attribute {
        display: flex;
        align-items: center;
        color: $secondary-text-color;

        svg {
          color: inherit;
          margin-right: 0.5em;
        }
      }
    }
  }
}
