.secondBoarding {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  max-width: 84%;

  .icon-provider {
    > svg {
      color: $primary-color;
    }

    > span {
      opacity: 0.7;
    }
  }

  .ant-progress-bg {
    height: 15px !important;
  }

  .pageCount {
    margin-top: 1em;
    opacity: 0.7;
  }

  .question-wrapper__question {
    color: $primary-text-color !important;
    font-size: 1.6em;
    font-weight: 600;
  }

  .choice-card {
    cursor: pointer;

    &:hover,
    & {
      transition: ease transform 0.5s;
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  @include breakpoint(min_sm) {
    font-size: 1.3em;

    .choice-card__inner {
      min-height: 220px;
    }
  }

  .lead-qualifier-display-v2 {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;

    h1,
    h3 {
      color: $color-high-contrast !important;
      margin: 0 !important;
    }

    .ant-card-body {
      color: $color-high-contrast !important;
    }

    .main-card {
      border-radius: 12px;
      background: #21242e;
      border: none !important;
      margin-bottom: 1em;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;

      display: flex;
      align-items: center;
      height: 8em;

      .ant-card-body {
        width: 100%;
      }

      &:hover {
        transform: scale(1.03);
        box-shadow: 0 4px 12px rgba(107, 70, 193, 0.3);
      }

      .card-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 85%;

        .icon-image {
          position: absolute;
          right: 0px;
          bottom: 0px;
          height: 7em;
        }
      }

      .icon-container {
        font-size: 1.5rem;
        color: #a3a3a3;
        padding-right: 1em;
      }

      .choice-title {
        flex: 1;
        text-align: left;
        font-size: 1.25em;
        color: $color-high-contrast;
      }

      .ant-card-head,
      .ant-card-head-title {
        border: none !important;
        color: $color-high-contrast !important;
      }

      @media only screen and (max-width: 920px) {
        padding: 0px;

        .ant-card-body {
          padding: 13px;
        }

        .choice-title {
          font-size: 18px;
        }

        .card-content {
          .icon-image {
            height: 4em;
          }
        }
      }
    }

    textarea {
      background-color: #2f323e;
      color: $color-high-contrast;
      border-radius: 12px;
      border: $medium-light-grey;
    }

    .success-div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      position: relative;
      background-color: #2c4034;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 3rem;
      width: 100%;
      padding: 3em;

      @include media('<sm') {
        margin-top: 10%;
      }

      .success-image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
      }

      .success-tic {
        height: 4em;
        width: 4em;
        z-index: 1;
        margin-bottom: 0.5em;
      }

      .success-text {
        font-size: 1.5rem;
        font-weight: 600;
        color: $color-high-contrast;
        margin-bottom: 0;
      }

      .success-subtext {
        margin: 6px;
        font-size: 1.1rem;
        color: $color-high-contrast;
      }
    }
    & .guideline-btn-prev {
      width: min-content;
      color: white;
      border: none;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      background-color: transparent;
      &:hover {
        color: white !important;
      }
    }
  }

  @include media('<sm') {
    width: 95%;
    max-width: 100%;
  }
}

ol.progtrckr {
  display: none;
}

[current-route='onboarding-fragen'] {
  .ui__content {
    padding: 75px !important;

    @include media('<=sm') {
      padding: 0px !important;
    }
  }
}
