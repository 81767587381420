.funnel-builder {
    &__bottom-bar {
        background: white;
        border: 1px solid;
        height: 70px;
        position: fixed;
        bottom: 0;
        right: 30px;
        width: inherit;
    }

    &__form-content {
        margin-top: 25px;
    }

    &__save-button {
        position: fixed !important;
        top: 12px;
        right: 40px;
        min-width: 115px;
        z-index: 100;
    }

    .rdw-editor-main {
        border: 1px solid $form-border-grey;
    }

    .rdw-editor-toolbar {
        border: initial !important;
        padding: 0 !important;
    }

    .rdw-editor-main {
        border: 1px solid $form-border-grey;
        padding: 0 1em;
        font-weight: 100;
    }

    .rdw-image-modal-size-input:first-child {
        pointer-events: none !important;
        cursor: all-scroll !important;
        opacity: 0.2 !important;
    }

    .rdw-option-wrapper {
        min-height: 30px;
        min-width: 30px;
    }

    .rdw-link-wrapper.testimonial-draft-js-option {
        &, .anticon {
            color: #1890ff;
        }
        .anticon {
            margin-right: 5px;
        }
    }

    .ant-steps-item-finish {
        cursor: pointer;
    }

    .ant-btn-ghost {
        height: initial !important;
        border: 0 !important;
        padding: 0 !important;
        margin-bottom: 0 !important;
    }

    .ant-empty {
        max-width: 600px;
        margin: 0 auto;
        padding: 75px 0;

        .ant-empty-image {
            margin-bottom: 35px;
        }
    }

    &__success {
        .ant-empty-image {
            color: $green;
        }
    }
}
