.analytics {
  > .ant-row {
    padding: 8px 0;

    .ant-card {
      height: 100%;
    }
  }

  .ant-card {
    &-head-title {
      .anticon-info-circle {
        color: rgba(0, 0, 0, 0.45);
        font-size: 0.8em;
        margin-left: 0.5em;
      }
    }
  }

  .source-logo {
    width: 20px;
    margin-right: 3px;
  }

  & path[fill="#OrangeGardient"] {
    fill: url(#OrangeGardient);
  }

  .custom-tooltip {
    background: #fff;
    padding: 10px 15px;
    border: 1px solid #ddd;
    max-width: 320px;
  }

  .ant-card {
    .ant-table thead > tr > th,
    .ant-table tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      // padding: 8px 14px;
    }

    @include breakpoint(max_sm) {
      margin: 6px 0;
      height: initial !important;

      .ant-card-head {
        min-height: initial;
        padding: 0 15px;

        .ant-card-head-title {
          padding: 6px 0;
        }
      }

      .ant-card-body {
        padding: 6px 15px;
      }
    }
  }

  .page-hits-mobile {
    &__row {
      margin: 5px 0 10px;
    }
  }

  .ant-layout-sider {
    max-width: 50px !important;
    min-width: 50px !important;
    background-color: white !important;
    padding-left: 10px !important;
  }

  .ant-layout-sider-light {
    max-width: 50px !important;
    min-width: 50px !important;
    background-color: white !important;
    padding-left: 10px !important;
  }

  .ant-statistic {
    .anticon-info-circle {
      font-size: 0.8em;
      margin-left: 0.5em;
    }
  }
}
