/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.align-right div {
  text-align: right;
}
.align-center div {
  text-align: center;
}
.align-left div {
  text-align: left;
}
.font-small div {
  font-size: 0.5em;
}
.font-medium div {
  font-size: 1.5em;
}
.font-large div {
  font-size: 2.5em;
}

.rich-text {
  &__wrapper {
    * {
      word-wrap: break-word;
    }

    & p {
      line-height: 1.5em;
      padding: 0.2em 0 0.4em;
      margin: 0;
    }

    h1,
    h2,
    h3 {
      font-size: 1em;
    }

    ol li::before {
      display: flex;
      line-height: 1.4em !important;
    }

    ul li:has(span[style*='font-size: 1em']),
    ol li:has(span[style*='font-size: 1em']) {
      &::before,
      &::marker {
        font-size: 1em !important;
        left: -25px !important;
        line-height: 1.6em !important;
      }
    }
    ul li:has(span[style*='font-size: 1.5em']),
    ol li:has(span[style*='font-size: 1.5em']) {
      &::before,
      &::marker {
        font-size: 1.2em !important;
        left: -30px !important;
        line-height: 1.2em !important;
      }
    }
    ul li:has(span[style*='font-size: 2.5em']),
    ol li:has(span[style*='font-size: 2.5em']) {
      &::before,
      &::marker {
        font-size: 2em !important;
        left: -45px !important;
        line-height: 1.2em !important;
      }
    }
    ul li:has(span[style*='font-size: 3.5em']),
    ol li:has(span[style*='font-size: 3.5em']) {
      margin-left: 2.5em !important;
      &::before,
      &::marker {
        font-size: 3em !important;
        left: -50px !important;
        line-height: 1.15em !important;
      }
    }

    ul li:has(span[style*='font-weight: bold']),
    ol li:has(span[style*='font-weight: bold']) {
      &::before,
      &::marker {
        font-weight: bold;
      }
    }
  }

  &__inner > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
