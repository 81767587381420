.menu-conatiner {
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  min-width: 250px;
  .menu-item-icon {
    font-size: 18px;
    color: $primary-color;
  }
  .menu-item {
    font-size: 16px;
    padding: 10px;
    border-bottom: 2px solid $light-grey-medium;
  }
  .menu-item:last-child {
    border-bottom: none;
  }
  .menu-item-link {
    color: $primary-text-color;
    padding: 0px 10px;
  }
}
