.account-sharing {
    form#dynamic_form_nest_item {
        margin-top: 30px;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space-item:first-child {
        width: 100%;
    }
}
