/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.rangeslider-wrapper {
  h2 {
    text-align: center;
    word-break: break-word;
    font-size: 1.5em;
  }
}
.rangeslider {
  &-wrapper {
    h2 {
      text-align: center;
      word-break: break-word;
      font-size: 1.5em;
    }
  }

  &__handle {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.01) !important;
    &:after {
      display: none !important;
    }
  }
}
