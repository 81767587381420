.widget-footer-wrapper {
  &__link,
  &__branding a {
    text-decoration: none;
  }
  &__branding {
    .custom-branding-img {
      max-height: 30px;
      width: auto;
      margin-left: 7px;
    }
  }
}
