.voice-recorder {
    audio {
        width: 100%;
        height: 35px;
        margin-right: 6px;
    }
}

.is-recording {
    animation: pulse 1.5s infinite;
}

.recorded-audio-upload-btn{
  margin: 0 10px;
  width: 130.6px;
}

@keyframes pulse {
    0% {
      -moz-transform: scale(0.95);
      -ms-transform: scale(0.95);
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
    }
    70% {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(90, 153, 212, 0);
    }
    100% {
      -moz-transform: scale(0.95);
      -ms-transform: scale(0.95);
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
    }
  }
  
@media only screen and (max-width: 1440px) {
  .recorded-audio-upload-btn{
    margin: 10px 0px;
  }
}