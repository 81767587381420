.react-select-option {
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    padding: 8px 0;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    display: flex;
    line-height: initial;
    justify-content: space-between;

    .label {
        float: left;
        line-height: 25px;
    }

    .title {
        max-width: 100%;
        overflow: hidden;
        margin-right: 8px;
    }

    &.selected-option {
        padding: 6px 3px;

        .label {
            div {
                line-height: 1;
            }

            input {
                height: 0;
                width: 0;
                position: absolute;
            }
        }
    }

    &.disabled {
        color: grey !important;

        & .icon-circle {
            background-color: grey !important;
        }

        &:hover {
            background-color: transparent !important;
        }
    }

    .anticon-delete {
        margin-left: 5px;
        color: $red;
    }

    .anticon-plus {
        color: $green;
    }
}

.calendar-builder {
    .settings-title {
        font-size: 14px;
        font-weight: normal;
    }
}
