.question-wrapper {
  text-align: center;

  &__question {
    color: #fff;
    font-size: $font-size-bigger;
    margin-bottom: 1.25em;
    font-weight: 300;
    padding: 0 0.9em;

    @include breakpoint(max_height_600) {
      font-size: 1.25em;
      margin-bottom: 0.5em;
    }

    @include breakpoint(max_370) {
      font-size: 0.975em;
      margin-bottom: 0.5em;
    }

    @include breakpoint(max_height_550) {
      font-size: 1.15em;
    }
  }

  &__message {
    .default-cta-button {
      margin-top: 1em;
    }

    .question-wrapper__question {
      margin-bottom: 0.5em;
    }
  }

  &__logo {
    height: 90px;
    display: block;
    margin: 0 auto 1.25em;

    @include breakpoint(min_1000) {
      height: 120px;
    }

    @include breakpoint(max_330) {
      height: 65px;
    }

    @media (max-height: 640px) {
      height: 75px;
      margin: 0 auto 0.85em;
    }

    @media (max-height: 480px) {
      height: 60px;
    }
  }

  &__pre-title {
    display: block;
    font-weight: 300;
    margin-bottom: 0.25em;
  }

  .user-info {
    padding: 0;

    .play-circle {
      @media (max-width: 375px) {
        i {
          font-size: 0.8em;
        }

        width: 30px;
        height: 30px;
      }
    }
  }
}

.wireframe-mode {
  h3 {
    background-color: $light-grey-medium;
    color: $light-grey-medium !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .choice-card__inner {
    label {
      background: $light-grey-medium !important;
      color: $light-grey-medium !important;
    }
  }

  .empty-line {
    background: #ececec;
    height: 25px;
    margin: 0 auto;
    margin-bottom: 5px;

    &-50 {
      width: 50%;
    }

    &-80 {
      width: 80%;
    }

    &:nth-child(2) {
      margin-bottom: 55px;
    }
  }
}

.default-theme {
  .question-wrapper {
    &__message {
      p {
        color: #fff !important;
      }

      .default-cta-button {
        background: #fff !important;
      }
    }
  }
}

.safari-theme {
  .question-wrapper {
    &__message {
      .default-cta-button {
        color: #fff !important;
      }
    }
  }
}
