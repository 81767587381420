.email-settings-builder {
    .ant-tabs-bar {
        border-color: $default-grey !important;
        margin: 0 0 25px 0 !important;
    }

    .ant-tabs-tab {
        background: #fff !important;
        border: 1px solid $default-grey !important;

        &-active {
            background: initial !important;
            border-color: $default-grey !important;
            padding: 8px 25px !important;
            border-bottom: 1px solid $light-grey !important;
        }
    }
    
    .ant-tabs, .email-sender {
        max-width: 800px;
        margin: 0 auto;
    }

    .email-sender {
        margin-bottom: 25px;

        .ant-form-item {
            margin-bottom: 0;
        }
    }
}