.meetovo-funnel-builder-layout {
  .mobile-view {
    @import '../elements/MobileStyles';

    // Please put only styles here which should only apply within the builder the rest need to be in mobile styles on funnle and dashboard project
    .column-grid-container {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .rich-text__wrapper {
      h1, h2, h3 {
        [style*='font-size: 3.5em'] {
          font-size: 3em !important;
        }
        [style*='font-size: 2.5em'] {
          font-size: 2em !important;
        }
        [style*='font-size: 1.5em'] {
          font-size: 1.25em !important;
        }
        [style*='font-size: 1em'] {
          font-size: 1.6em !important;
        }
      }
    }
  }
}
