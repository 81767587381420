.theme-settings {
    &__color-pickers {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        
        margin-bottom: 25px;
    }

    div[id^="font-picker"] {
        box-shadow: initial;

        .dropdown-button {
            height: 40px;
            padding: 0 15px;
            background: initial;
            border: 1px solid $form-border-grey;
            border-radius: $default-border-radius;
        }
    }
}
