.add-funnel-and-folder-button-container {
  position: fixed;
  bottom: 25px;
  z-index: 99999;
  right: 110px;
  width: 40px;
  height: 40px !important;

  .circle-button,
  .circle-button:hover,
  .circle-button:active,
  .circle-button:focus,
  .circle-button.active {
    transform: translateY(-3px);
    background: $color-high-contrast;
    border-radius: 50%;
    font-size: 18px;
    border: none !important;
    width: 40px !important;
    height: 40px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 0px !important;
  }

  .circle-button:hover {
    transform: scale(1.03);
  }

  .circle-button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    svg {
      // fill: url(#MyGradient);
      color: $primary-color;
    }
  }

  @include breakpoint(max_sm) {
    display: none;
  }
}

.menu-conatiner {
  @extend .menu-conatiner;
  min-width: fit-content !important;
  padding: 5px !important;
  .menu-trigger {
    padding: 0.62rem;
    display: flex;
    align-items: center;
    p {
      font-size: 15px;
      margin-left: 5px;
      margin-bottom: 0px !important;
    }
  }
}

.remove-dropdown-padding {
  .menu-item {
    padding: 0px !important;
  }
  .ant-dropdown-menu-item {
    padding: 0px !important;
  }
}

.menu-item-with-parent-div {
  .anticon {
    margin-right: 6px;
  }
}

.left-align-add-btn {
  margin-right: 46px !important;
  transition: all .4s;
}