.voice-recorder {
  #record-btn {
    margin-right: 10px;
  }

  button {
    margin-top: 2px;
  }
  .flex-group {
    display: flex;
    align-items: center;
  }
  audio {
    margin-top: 0px;
    margin-left: 0px;
    min-width: 320px;
  }
}
