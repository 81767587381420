.range-slider__label {
    font-size: 3.75em;
    color: #fff;
}

.rangeslider {
    &-horizontal {
        max-width: 700px;
        margin: 20px auto !important;

        .rangeslider__fill {
            background-color: $secondary-color !important;
        }
    }
}
