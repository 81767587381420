.custom-logo-size {
  text-align: center;
  img {
    width: 20px;
    margin-right: 8px;
    margin-top: -2px;
  }
}

.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Change the number of lines after which to show ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageDetailContainer {
  display: flex;
  justify-content: space-between;
}

.emailDetails, .messageDetails {
  text-align: right;
}

.messageSentDate {
  text-align: right;
  flex: 1;
}

.emailHeaderDetails {
  margin-top: 5px;
}

.messagePreview {
  margin-top: 10px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.detailFlexContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.detailSection {
  text-align: right;
}

/* Details for email or messages */
.emailDetails,
.messageDetails {
  flex: 1;
}

/* Styling for buttons */
.cancelButton {
  background-color: red;
  color: white;
  border: none;
}

.cancelButton:hover {
  background-color: darkred;
}

/* DatePicker customization */
.datePickerCustom {
  margin-top: 5px;
}

/* Text alignment and spacing */
.rightAlignedText {
  text-align: right;
}

.topSpacing {
  margin-top: 10px;
}

.sentDateDisplay {
  text-align: right;
  flex: 1;
  font-style: italic;
}

/* Specific for preview sections */
.previewContent {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  word-break: break-all;
}
