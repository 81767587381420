/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.voice-message-preview {
  padding: 0.35em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  .voice-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 146px;
    height: 146px;
    border-radius: 50%;
    border: 2px solid white;
    animation-name: color;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    position: relative;

    .play-circle {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 30;
      box-sizing: content-box;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      * {
        color: #fff;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      &:before,
      &:after {
        content: '';
        position: absolute;
        background: #ff3924;
        border-radius: 50%;
        animation-duration: 2s;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        opacity: 0;
        z-index: -1;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        display: block;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        animation-name: pulse-border-2;
      }

      &:after {
        z-index: -1;
        animation-duration: 1.3s;
        transition: all 200ms;
      }
    }

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
      position: relative;
    }

    &.is-playing {
      &:before,
      &:after {
        border-radius: 50%;
        display: block;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }

      &:before {
        animation: pulse-border-2 2s ease-out infinite;
      }

      &:after {
        z-index: 1;
        animation: pulse-border-2 1.35s ease-out infinite;
        transition: all 200ms;
      }
    }

    @include breakpoint(max_330) {
      width: 55px;
      height: 55px;
    }

    @include breakpoint(max_height_600) {
      width: 100px;
      height: 100px;
    }
  }
}

@keyframes color {
  0% {
    background-color: #aaa;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #eee;
  }
}

@keyframes pulse-border-2 {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  40% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.15);
    opacity: 0.7;
  }
  70% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0.3;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
