.feature-container {
  max-width: 800px;
  margin: auto;
  margin-top: 8%;

  .feature-title {
    font-size: 1.35em;
    line-height: 1.25;
    text-align: center;
    color: white;
    font-weight: 600;
    margin-top: 14%;
    margin-bottom: 0px !important;
    width: 100%;
  }

  .feature-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;

    .feature-tagline {
      border-radius: 50px;
      background-color: #24353ee5;
      font-size: 0.75em;
      color: #eaecf0;
      text-align: center;
      margin-bottom: 1em;
      padding: 0.25em 1em;

      cursor: pointer;
      &:hover {
        background-color: #eaecf0;
        color: #24353ee5;
      }
    }
  }

  .feature-grid {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 6%;
    flex-wrap: wrap;

    .feature-line {
      border-radius: 20px;
      width: 32%;
      overflow: hidden;
      background-color: #21242d;
      flex-direction: column;
      margin-bottom: 2rem;

      @include media('<sm') {
        width: 95%;
      }

      .feature-thumbnail {
        height: 170px;
        background-size: cover;
        background-position: center;
      }

      .feature-content {
        padding: 15px 13px;

        .feature-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: white;
        }

        .feature-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #afb0b4;
        }
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .feature-grid {
    width: 100%;
  }
  .feature-line {
    min-width: 283px;
    margin: auto;
  }
}
