.agency-dashboard {
  & .wrapper {
    margin-top: 30px !important;
  }
  .basic-settings,
  .branding-settings {
    height: 100%;
    .anticon {
      color: lighten($ant-primary-color, 25%);
      &.active {
        color: $ant-primary-color;
        &:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }
    .ant-input-group-addon {
      svg {
        cursor: pointer !important;
        &:hover {
          cursor: pointer !important;
        }
      }
    }
  }

  .branding-settings {
    .color-circle b {
      font-weight: initial !important;
    }
  }

  .customers-table {
    .ant-card-body {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .ant-card-extra {
      svg {
        color: $ant-primary-color;
        font-size: larger;
      }
    }
    .single-customer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(214, 212, 212, 0.37);

      &:last-child {
        border-bottom: 0px;
      }
      .customer-info {
        display: flex;
        flex-direction: column;
        strong {
          width: 100%;
          min-width: 200px;
        }
      }
    }
  }

  .image-upload__container .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 10px;
  }

  .image-upload__container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    max-height: 60px !important;
  }

  .color-circle__circle {
    margin-right: 10px;
  }

  @media screen and (max-width: 1450px) {
    .color-circle__circle {
      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      border: 2px solid #fff;
    }

    .image-upload__container span.anticon.anticon-cloud-upload {
      font-size: 180%;
    }
  }
}

.image-uploader-plain {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-bottom: 5px;

  .selected-image {
    cursor: pointer;
    position: relative;
    &::after {
      background-color: rgba(192, 192, 192, 0.363);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    &:hover {
      transform: scale(1.02);
    }
  }
  label {
    margin-bottom: 5px;
  }
}

.iframe-modal-wrapper {
  height: 96vh !important;
  margin-top: 20px;
  .ant-modal {
    margin: 0 !important;

    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          display: flex;
          align-items: center;
          justify-content: center;

          .view-changer {
            display: flex;
            align-items: center;

            .preview-size-icon {
              font-size: 20px;

              &.active {
                color: $primary-color;
                font-size: 22px;
                transition: font-size 0.1s;
              }
            }
          }
        }
      }
      .ant-modal-body {
        padding: 0 !important;

        iframe {
          border: none !important;
        }
      }
      .ant-modal-footer {
        display: none !important;
      }
    }
  }
}
