.image-upload {
  &__upload-field {
    margin-bottom: 20px;

    .replace-icon-style {
      color: white;
      font-size: 16px;
      padding-right: 2px;
    }
  }

  &__container {
    position: relative;
    .ant-upload.ant-upload-select-picture-card > .ant-upload {
      padding: 1.5rem;
    }

    .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container,
    .ant-upload-list-picture-card .ant-upload-list-item {
      margin: 0 !important;
      padding: 0 !important;
    }

    .ant-upload-list,
    .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container,
    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload-picture-card-wrapper {
      width: 100% !important;
      height: 100% !important;
      border-radius: 8px;
      overflow: hidden;
    }

    .ant-upload-list-item-actions {
      display: flex;
      justify-content: center;
    }

    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
    }

    span.anticon.anticon-cloud-upload {
      font-size: 175%;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
      opacity: 1;
      padding: 5px;

      img {
        width: initial !important;
        height: initial !important;
        max-height: 100% !important;
        margin: 0 auto !important;
      }
    }
  }

  &__no-images {
    .ant-upload-list {
      height: 0 !important;
    }
  }
}
.img-crop-modal {
  .reactEasyCrop_Container {
    background: radial-gradient(#a1a1a1, transparent);
  }
  .img-cropper-ui--wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

    .btn-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      justify-content: space-around;
      max-width: 200px;
      margin-top: 12px;

      button {
        background-color: transparent;
        border: 1px solid $form-border-grey;
        padding: 10px;
        aspect-ratio: 1 / 1;
        max-height: 150px;
        margin-bottom: 10px;
        cursor: pointer;

        &:focus,
        &:hover {
          transform: scale(0.98);
        }
        &.active {
          border: 1px solid $primary-color;
          box-shadow: 3px 5px 15px 3px rgb(0 0 0 / 20%);

          &:hover {
            transform: none;
            cursor: default;
          }
        }
      }
      button:nth-child(1) {
        border-radius: 50%;
      }
      button:last-child {
        min-width: 80px;
        aspect-ratio: auto;
      }
    }
    .aspect-ratio-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      max-width: 350px;
      min-height: 81px;
      button {
        padding: 10px;
        border: 1px solid $form-border-grey;
        background-color: transparent;
        font-weight: 600;
        cursor: pointer;

        &:focus,
        &:hover {
          transform: scale(0.98);
        }

        &.active {
          border: 1px solid $primary-color;
          box-shadow: 3px 5px 15px 3px rgb(0 0 0 / 20%);

          &:hover {
            transform: none;
            cursor: default;
          }
        }
      }
      .ratio {
        &-5-5 {
          aspect-ratio: 1 / 1;
        }
        &-4-3 {
          aspect-ratio: 4 / 3;
        }
        &-16-9 {
          aspect-ratio: 16 / 9;
        }
        &-4-6 {
          aspect-ratio: 4 / 6;
        }
        &-5-1 {
          aspect-ratio: 5 / 1;
        }
      }
    }
    .slider-container {
      width: 100%;
      margin-left: 20px;
      display: flex;
    }
  }
}