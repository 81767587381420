.coming-soon {
  padding: 6% 0;

  img {
    max-width: 100%;
  }

  .ant-empty-description {
    max-width: 650px;
    margin: 30px auto 30px;
  }
}
