.ads-builder-template {
  margin-bottom: 16px;
  background: #fff;
  padding-bottom: 0 !important;
  box-shadow: 2px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 12px;
  border: 1px solid #e9e9e9;
  padding-top: 20px;

  &__modal {
    .ant-modal-body {
      padding: 38px 48px;
    }
    .ant-steps-item-description {
      max-width: none !important;
    }
  }

  &__tab {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #50d4d4 !important;
    }
    .ant-tabs-ink-bar {
      background-color: #50d4d4 !important;
    }

    .ant-tabs-tab:hover {
      color: #50d4d4 !important;
    }
  }

  &__wrapper {
    padding-bottom: 2rem;

    .ant-btn-block {
      text-align: left;
      padding: 0;
    }
  }

  &__label {
    padding-bottom: 0.5em;
    display: block;

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .title-wrapper {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .use-template {
    cursor: pointer;
    color: rgb(0, 0, 255);
    &:hover {
      text-decoration: underline;
    }
  }

  .title-wrapper,
  .profile-section,
  .read-more-content {
    padding: 0 20px;
  }
}

.profile-section {
  display: flex;
  margin-bottom: 1em;
  &__inner {
    .content {
      padding-left: 8px;
    }
    h5 {
      font-weight: 600;
      color: RGB(20, 20, 20);
      font-size: 1.05em;
      margin-bottom: 0px;
    }
    span {
      display: flex;
      align-items: center;
    }
    p {
      margin-bottom: 0px;
      font-size: 1em;
      padding-right: 0.5em;
    }
    display: flex;
    align-items: center;
    .user_avatar {
      border-radius: 50%;
      height: 45px;
      width: 45px;
    }
  }
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 20px 25px;

  &__inner {
    width: 55%;
    .plain-text-editor {
      p {
        font-weight: 400;
        color: #333;
      }
    }
  }

  p {
    margin-bottom: 0px;
    color: rgb(192, 192, 192);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 1.25em;
    line-height: 1.25em;
    padding-right: 1em;
  }

  .ant-btn-primary {
    background: #e7e7e7 !important;
    border: 1px solid $form-border-grey !important;
    padding: 10px 25px;
    height: initial;
    border-radius: 5px;
    color: #aaa;
    text-shadow: initial;
    cursor: not-allowed;

    &:hover {
      color: #aaa !important;
    }
  }
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}
.read-more-content {
  white-space: break-spaces;

  p {
    margin-bottom: 0;
  }

  button {
    margin-bottom: 20px;
  }
}

.ads-builder-template__wrapper {
  &:hover {
    .trash-icon-wrapper {
      display: flex !important;
      justify-content: flex-end;
    }
  }
  .trash-icon-wrapper {
    display: none;
  }
  .trash-icon {
    color: $dark-orange;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      color: $orange;
    }
  }
}
