.google-job-banner {
    width: 100%;
    position: sticky;
    top: 64px;
    z-index: 15;
    background: white;
    border-bottom: 1px solid $form-border-grey;

    padding: 20px 40px 20px 40px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__logo {
        max-width: 120px;
    }

    &__search-bar {
        max-width: 600px;
        width: 65%;
    }
}