.builder__content {
  .container__wrapper {
    min-height: 260px;
    width: 100%;
    .empty-container-layout {
      min-height: 300px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 28px;
        color: #000000;
      }
      &__inner {
        max-width: 420px;
        transition: 1s all ease-in-out;
        animation: pulse-opacity 2s ease-in-out 0s infinite normal;

        &:hover {
          animation: none;
        }
      }
    }
  }
  .container__wrapper:hover,
  .container__add-wrapper:hover,
  .active-actions-controller__wrapper {
    .column-wrapper {
      border: 1px dashed #ddd !important;
    }
  }
  .column-grid-container {
    min-height: inherit;
    display: flex;
    flex-direction: row;
    border: none;
    pointer-events: none;
    background-color: inherit;
    margin-inline: 20px;
    .column-parent-wrapper {
      padding: 0.25em;
    }
    .column-wrapper {
      padding: 2px;
      background: inherit;
      pointer-events: auto;
      height: 100%;
      min-height: 40px;
      border: 1px solid transparent;
    }
  }
  .ad-builder-banner-container {
    background: linear-gradient(342deg, rgba(48, 100, 145, 1) 10%, rgba(31, 41, 109, 1) 70%);
    max-width: 100%;
    border-radius: 10px;
    margin-left: 0px;
    .main-content {
      margin-left: 30px;
      padding-top: 40px;
      padding-bottom: 27px;
      h4,
      p {
        color: white;
      }
      .banner-button {
        color: black;
        text-decoration: none;
        border-radius: 5px;
      }
    }
    .banner-icon {
      max-width: 250px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@keyframes pulse-opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
