.lead-qualifier-logic-builder {
    &__logic-block {
        margin-bottom: 1em !important;
    }

    &__trigger {
        position: absolute;
        right: 20px;
        bottom: 15px;
        font-size: 0.9em;
        cursor: pointer;
    }
}
