.trail-banner-container {
  background-color: $light-pink;
  max-height: 52px;
  border-radius: 5px;
  margin-right: 30px;

  .banner-date {
    float: left;
    width: 55px;
    border: 1px solid #ffaebc;
    margin: 5px;
    text-align: center;
    border-radius: 5px;
    color: #ff5f77;

    .date-number {
      line-height: 1;
      font-size: 16px;
      padding-top: 4px;
    }
    .date-text {
      line-height: 1.5;
    }
  }

  .banner-text {
    width: 280px;
    line-height: 1.5;
    float: right;
    padding: 5px;
    p {
      font-weight: bold;
      margin: 0px;
      color: #262626;
    }
    span {
      color: #ff5f77;
      font-weight: bold;
      cursor: pointer;
    }

    @include media('<=sm') {
      display: none;
    }
  }

  @include breakpoint(max_sm) {
    width: 80%;
    margin-right: 1.5em;

    .banner-date {
      display: none;
    }
    .banner-text {
      width: 100%;
      line-height: 1.2;
      font-size: 12px;
      float: right;
      padding: 8px 10px;
      span {
        color: #ff5f77;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
