.funnel-selector {
  .ant-card-actions {
    > li {
      max-width: 55px;
      float: right;
    }
  }
}

.funnel-overview {
  height: 100%;

  &__add-card {
    min-height: inherit;
    width: 100% !important;
    margin-inline: 12px !important;
    margin-bottom: 24px !important;
  }

  &__card {
    margin-bottom: 21px !important;

    &__image-placeholder {
      height: 200px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 90px;

      img {
        object-fit: unset !important;
        height: 95px !important;
        margin-left: -8px;
      }

      &__overlay {
        position: absolute;
        top: 25%;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, #737373 0%, rgba(115, 115, 115, 0) 100%);
        pointer-events: none;
        height: 150px;
        transition: transform 0.5s ease, height 0.5s ease;
      }

      &__countdown {
        position: absolute;
        bottom: 55px;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        font-size: 18px;
        text-align: center;
        transition: transform 0.5s ease, bottom 0.5s ease;
      }
    }

    &__drag-handle {
      position: absolute;
      left: 3px;
      top: 4px;
      cursor: move;
    }

    &__action {
      cursor: pointer;
      display: flex;
      align-items: center;

      &.card-disabled {
        filter: grayscale(1);
        opacity: 0.25;
        pointer-events: none;
      }
    }

    &.card-disabled {
      .ant-card {
        &-cover,
        &-body {
          filter: grayscale(1);
          opacity: 0.25;
          pointer-events: none;
        }
      }
    }

    &-bottom {
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
    }

    &__title {
      color: rgb(0, 0, 0, 1) !important;
      font-weight: 600 !important;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &-wrapper {
        width: 60%;
      }
    }

    &__applications-count {
      display: none;
      color: rgb(0, 0, 0, 0.45);
    }

    &-actions-items-operations {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .funnel-overview__card {
    height: 260px;
    width: 100%;

    .ant-card-body {
      display: none;
    }

    .ant-card-cover {
      margin: 12px 12px 0px 12px;
      border-radius: 8px;
      overflow: hidden;
      height: auto;

      img {
        border-radius: 8px;
        transition: transform 0.5s ease, height 0.5s ease;
        width: 100%;
        height: 200px;
      }
    }

    .ant-card-actions {
      border-top: none !important;
      cursor: auto !important;
      background: white !important;
    }

    .ant-card-actions > li > span:hover {
      color: inherit !important;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }

    .ant-card-actions > li {
      text-align: left !important;
    }

    &:hover .funnel-overview__card__image {
      transform: scale(1.05);
      height: 178px;
    }

    &:hover .funnel-overview__card__image-placeholder__overlay {
      height: 130px;
    }

    &:hover .funnel-overview__card__image-placeholder__countdown {
      bottom: 67px;
    }

    &:hover .funnel-overview__card__applications-count {
      display: block;
    }
  }

  .ant-col:nth-last-child(-n + 3) {
    // @include breakpoint(min_576) {
    //     .funnel-overview__card {
    //         margin-bottom: 0 !important;
    //     }
    // }
  }

  .ant-card-cover img {
    object-fit: cover;
    object-position: top;
  }
}

.add-funnel-modal__trigger {
  // height: 100%;
  text-align: center;

  .ant-card {
    height: calc(100% - 24px);
    margin-bottom: 20px !important;

    &-body {
      text-align: center;
      width: 100%;
    }
  }

  h1 {
    font-size: 2em;
    display: block;
    font-weight: 300;
    color: $default-grey;
  }

  .ant-col {
    min-height: 344px;
  }
}

.add-funnel-folder-floating-icons {
  .anticon {
    font-size: 1.25em;
    color: $primary-color;
  }
}

.iframe-modal-wrapper {
  height: 96vh !important;
  margin-top: 20px;
  .ant-modal {
    margin: 0 !important;

    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          display: flex;
          align-items: center;
          justify-content: center;

          .view-changer {
            display: flex;
            align-items: center;

            .preview-size-icon {
              font-size: 20px;

              &.active {
                color: $primary-color;
                font-size: 22px;
                transition: font-size 0.1s;
              }
            }
          }
        }
      }
      .ant-modal-body {
        padding: 0 !important;

        iframe {
          border: none !important;
        }
      }
      .ant-modal-footer {
        display: none !important;
      }
    }
  }
}
