.welcome-page-builder-v2 {
    .tracking-settings__trigger {
        position: absolute;
        right: 60px;
        cursor: pointer;
        z-index: 1;
    }

    .edit-block {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        cursor: not-allowed;
    }

    .is-builder .row-active {
        z-index: 15;
    }
}

#_cbhtml [title="Vorlagen"] {
    background: #1890ff !important;
    color: #FFF !important;

    &, &:hover {
        transition: ease all 0.5s;
    }

    &:hover {
        opacity: 0.95;
    }
}

label.welcome-page-builder-wrapper__expert-switch.ui__label-switch {
    position: absolute;
    right: 60px;
    margin-top: 3px;
    z-index: 10;
}

.is-rte-tool {
    button.rte-more {
        display: none !important;
    }
}
