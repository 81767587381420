/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.footer-wrapper {
  &__link,
  &__branding a {
    text-decoration: none;
  }
  &__branding {
    .custom-branding-img {
      max-height: 30px;
      width: auto;
      margin-left: 7px;
    }
  }
}
