.publish-modal {
    .ant-modal-content {
        padding: 50px 50px 0 !important;

        a {
            margin-top: 1rem !important;
        }
    }

    .qr-code-wrapper {
        max-width: 140px;
        margin: 0 auto;
    }

    .copy-input {
        margin-top: 1.5rem !important;
    }
}
