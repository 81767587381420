.image-picker {
  .ant-input-suffix {
    height: 20px;
    display: block;
    img {
      height: 25px;
    }
  }
  &__image {
    position: relative;
    padding-bottom: 56.2%;
    animation-name: colorPulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    cursor: pointer;
    .anticon {
      position: absolute;
      z-index: 10;
      opacity: 0.75;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $secondary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      svg {
        width: 50%;
        height: 50%;
      }
    }

    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &.active {
      animation-name: initial;

      img {
        opacity: 0.2;
      }
    }

    &__author-info {
      transition: ease opacity 0.5s;
      opacity: 0;
      position: absolute;
      bottom: 0;
      padding: 0.25em 0.75em;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;

      a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 10px;
        &,
        &:hover {
          color: #fff;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:hover &__author-info {
      transition: ease opacity 0.5s;
      opacity: 1;
    }
  }

  .unsplash-logo {
    width: 110px;
    margin: 0 auto;
    display: block;
    position: absolute;
    top: 68px;
    right: 12px;
  }
}

@keyframes colorPulse {
  0% {
    background-color: #aaa;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #eee;
  }
}
