.onboarding__button {
  margin-top: 2em;
  background-color: #1a1b1f;
  background-image: linear-gradient(338deg, #1e266d, #23d3d3) !important;
  font-size: 1.2em;
  font-weight: bold;
  border: none !important;

  @include breakpoint(max_md) {
    margin-top: 1.5em;
  }
}

.react-player__play-icon {
  border-width: 30px 0 30px 50px !important;
}

.guideline-btn {
  padding: 10px 20px;
  border-radius: 80px;
  font-size: 16px;
  background: linear-gradient(90deg, #203574, #33c2c3);
  border: none;
  color: white;
  cursor: pointer;
}

.bg__white {
  background-color: $color-high-contrast;
}

.bg_lightgray {
  background-color: $light-grey;
}

.firstboarding {
  &__wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .image-background {
      position: absolute;
      top: -5vw;
      left: 0;
      width: 100vw;
      z-index: 1;
      opacity: 0.75;
    }

    .image-left,
    .image-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20vw;
      z-index: 1;

      @include media('<=md') {
        position: relative;
        top: auto;
        transform: none;
        width: 40vw;
        margin: 1em auto;
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      @include media('<=md') {
        flex-direction: row;
        justify-content: space-around;
        width: 95%;
      }
    }

    .image-left {
      left: 0;

      @include media('<=md') {
        left: auto;
      }
    }

    .image-right {
      right: 0;

      @include media('<=md') {
        right: auto;
      }
    }
  }

  &__antrow {
    max-width: 56vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .ant-col {
      @include media('<=md') {
        padding-bottom: 30px;
      }
    }

    @include media('<=md') {
      max-width: 90vw;
      margin-bottom: 20px;
    }

    h1 {
      width: 80%;
      margin: 0 auto 0.5em;
      font-size: 3.5em;
      line-height: 1.25;
      font-weight: 600;
      color: $color-high-contrast;

      @include media('<=md') {
        font-size: 2.2em;
        width: 100%;
      }
    }

    p {
      width: 85%;
      margin: auto;
      font-size: 1.5em;
      color: $color-high-contrast;

      @include media('<=md') {
        font-size: 1.2em;
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 2em;
    height: 100%;
    justify-content: center;

    @include media('<=md') {
      padding: 0;
      margin-top: 1em !important;
      text-align: center;
      align-items: center;
    }
  }
}

.stack {
  &__outer {
    padding-top: 2em;
    padding-bottom: 2em;
    position: relative;

    @include breakpoint(max_md) {
      padding: 0;
      margin-top: 8em !important;
      padding-bottom: 10em !important;
    }
  }
  &__top {
    position: inherit;
    z-index: 2;
    overflow: hidden;
    border-radius: 15px;

    .anticon-play-circle {
      font-size: 9em;
      color: $secondary-color;
      opacity: 0.6;
      animation: scalePulse 4s infinite;
    }
  }
  &__bottom {
    img {
      position: absolute;
      z-index: 1;
      top: -20%;
      left: -20%;
      width: 80%;

      @media (max-width: 991px) {
        width: 95%;
      }
    }
  }
}

[current-route*='onboarding-'] {
  .ant-layout-header {
    z-index: 120;
  }
}

@keyframes scalePulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
