@mixin size($w, $h) {
    width: $w;
    height: $h;
}
%clearfix {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

.sms-preview {
    display: flex;
    justify-content: center;
}

.chat {
    @include size(310px, 380px);
    border-radius: 12px;
    background-color: $light-grey;
    overflow: hidden;
    border: 1px solid #ddd;

    &-mock {
        background: linear-gradient(180deg, #eee, #fff);

        &__left {
            border-radius: 15px 0 0 0;
        }

        &__right {
            border-radius: 0 15px 0 0;
        }

        &__small {
            margin-top: 120px;
            width: 90px;
            filter: blur(6px);
            opacity: 0.85;
        }

        &__big {
            margin-top: 50px;
            width: 110px;
            filter: blur(2px);
            opacity: 0.95;
        }
    }

    &-title {
        background-color: #fff;
        @extend %clearfix;
        @include size(100%, 50px);
        border-bottom: 1px solid $light-border-grey;

        h2 {
            margin: 10px 0;
            padding: 0;
            color: $primary-text-color;
            font-weight: normal;
            font-size: 16px;
            display: inline-block;
            line-height: 30px;
        }
        .avatar {
            float: left;
            margin: 10px;
            padding: 0;
            @include size(30px, 30px);
            img {
                border-radius: 50%;
                @include size(30px, 30px);
            }
        }
    }
}
.messages {
    @include size(100%, calc(100% - 90px));
    &-content {
        @include size(100%, 100%);
        overflow-y: auto;
        box-sizing: border-box;
        .message {
            max-width: calc(100% - 76px);
            padding: 8px;
            margin: 8px 0 8px 12px;
            border-radius: 5px;
            background-color: rgba(black, 0.1);
            color: #e3e3e3;
            float: left;
            font-size: 13px;
            position: relative;
            word-break: break-all;
            clear: both;
            animation: pop 0.3s forwards;
            color: $secondary-text-color;

            .timestamp {
                position: absolute;
                right: -40px;
                bottom: 0;
                font-size: 12px;
                color: $primary-text-color;
            }
        }
    }
}
.action-box {
    background-color: #fff;
    @include size(100%, 40px);
    @extend %clearfix;
    border-top: 1px solid $light-border-grey;

    &-input,
    &-submit {
        box-sizing: border-box;
        border: 0;
        float: left;
    }
    &-input {
        @include size(calc(100% - 60px), 20px);
        display: flex;
        align-items: center;
        background-color: transparent;
        resize: none;
        color: white;
        margin: 10px 0;
        padding: 0 10px;
        line-height: 20px;
        font-size: 16px;
        letter-spacing: 1px;
        overflow: hidden;
        &::-webkit-input-placeholder {
            line-height: 20px;
            font-size: 13px;
        }
    }
    &-submit {
        @include size(60px, 40px);
        background-color: rgba(#bdc3c7, 0.2);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-color;
    }
    &-text-mock {
        background: rgba(black, 0.05);
        width: 90%;
        height: 10px;
        border-radius: 50px;
    }
}
