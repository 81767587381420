.connect {
  .ant-card {
    .connect__image {
      text-align: center;

      img {
        width: 100%;
        max-width: 100px;
        padding-right: 20px;

        @include breakpoint(max_575) {
          margin-bottom: 20px;
          padding-right: 0;
        }
      }
    }

    &-extra {
      .anticon {
        color: $secondary-color;
      }
    }
  }

  .ant-card-extra {
    .anticon {
      margin-left: 0.5em;
    }
  }

  @include breakpoint(max_575) {
    .ant-card-extra {
      > span:first-child {
        display: none;
      }
    }
  }
}

.connect-settings-changer {
  &__modal-inner {
    max-height: 500px;
    overflow-y: auto;
    position: relative;
  }

  &__add-button-wrapper {
    padding-top: 12px;
    border-top: 1px solid #e8e8e8;
  }

  .ui__white-round-wrapper {
    padding: 0;
  }
  .webhook-link {
    max-width: 80%;
  }
  .funnel-tags {
    background: $light-grey-medium;
    font-size: 15px;
    padding: 3px 4px 3px 10px;
    margin: 2px 4px 2px 0px;
    border: 0px;
  }
}

.zapier-modal{
  margin-bottom: 8px;
    .anticon {
      color: $secondary-color;
      margin-left: 2px;
    }
}