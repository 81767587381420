@import 'Common.scss';

.template-block {
  .ellipsis-title {
    @extend .ellipsis-title;
  }
  .settings-title__right {
    transition: ease opacity 1s;
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    .settings-title__right {
      transition: ease opacity 1.5s;
      opacity: 1;
      cursor: pointer;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    border: 1px solid $form-border-grey;
    border-radius: $default-border-radius;
    box-shadow: 1px 1px solid;
    overflow: hidden;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 5%);
    position: relative;
    background-size: 1000% !important;
    overflow: hidden;

    &__active-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.6);

      svg {
        font-size: 2.5em;
        color: $green;
      }
    }

    .overlay {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 100%;
      z-index: 400;
      cursor: auto;

      backdrop-filter: blur(2px);
      background-color: rgba(0, 0, 0, 0.015) !important;
      display: none;

      .icon-shadow {
        border-radius: 0;
        background-color: unset !important;
        border: unset !important;
        backdrop-filter: unset !important;

        svg {
          background-color: white !important;
          border-radius: 50%;
        }
      }

      span {
        padding: 10px;
        border-radius: 50%;
        background-color: $light-green;
        border: 1px solid $border-green;
        backdrop-filter: blur(2px);
        cursor: pointer;

        svg {
          font-size: 40px;
          fill: $green;
        }
      }
    }

    &,
    &:hover {
      transition: all ease 0.5s;
    }

    &:hover {
      box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 5%);
      .overlay {
        display: flex;
      }
    }

    img {
      width: 100%;
      height: 180px;
      object-fit: contain;
      object-position: center;
    }

    .height-200 {
      height: 200px;
    }

    p {
      margin: 0;
    }
  }

  &__active {
    .template-block__inner {
      cursor: initial;

      &:hover {
        transform: initial;
        box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 5%);
      }
    }
  }
  .settings-title {
    @extend .settings-title;
  }
}
