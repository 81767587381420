.led {
    &,
    &:hover {
        transition: ease all 0.5s;
    }

    &:hover {
        transform: scale(1.2);
    }

    &__gray,
    &__green,
    &__red {
        margin: 20px auto;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &__gray {
        background-color: rgb(183, 182, 182);
        box-shadow: inset rgb(87, 86, 86) 0 -1px 9px, rgb(245, 243, 243) 0 2px 12px;
    }

    &__green {
        background-color: rgb(168, 250, 5);
        box-shadow: inset #460 0 -1px 9px, #7d0 0 2px 12px;
    }

    &__red {
        background-color: rgb(250, 5, 5);
        box-shadow: inset rgb(102, 26, 0) 0 -1px 9px, rgb(221, 118, 0) 0 2px 12px;
    }
}
