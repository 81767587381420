.sortable-list {
  margin-bottom: 45px;

  &__add {
    margin: 0 auto;
    margin-bottom: 35px !important;
    cursor: pointer;

    &,
    &:hover {
      transition: opacity ease 0.5s;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &__item {
    position: relative;

    .ant-form-item {
      &.ant-row {
        display: initial !important;
      }

      &-label,
      &-control {
        display: block !important;
        text-align: left !important;
      }
    }

    &__action-bar {
      transition: opacity ease 0.5s;
      opacity: 0;
      position: absolute;
      text-align: right;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 0 25px 5px;
    }

    &:hover {
      > .sortable-list__item__action-bar {
        transition: opacity ease 0.5s;
        opacity: 1;
      }
    }

    .sortable-list {
      margin-bottom: 0;
    }
  }

  &__manuel-sort {
    opacity: 0;
    left: 0;
    width: 30px;
    display: inline-block;
    top: 5px;
    position: absolute;
    transform: translateX(-100%);
    height: 100%;

    span {
      font-size: 1.15em !important;
      display: block !important;
      color: #cac9c9 !important;

      &:first-child {
        margin-bottom: 0.2em !important;
      }
    }
  }

  &__item:hover &__manuel-sort {
    opacity: 1;
  }

  &__item-handle {
    cursor: move;
  }
}

.testimonials .sortable-list__item .ant-form-item.ant-row {
  display: block !important;
}
