/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.container__wrapper {
  .align_start,
  .align_center,
  .align_end,
  .align_space-between,
  .align_space-around {
    .special-icon,
    .column-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .align_start {
    .column-wrapper__inner,
    .special-icon,
    .column-content {
      justify-content: start;
    }
  }
  .align_center {
    .column-wrapper__inner,
    .special-icon,
    .column-content {
      justify-content: center;
    }
  }
  .align_end {
    .column-wrapper__inner,
    .special-icon,
    .column-content {
      justify-content: end;
    }
  }
  .align_space-between {
    .column-wrapper__inner,
    .special-icon,
    .column-content {
      justify-content: space-between;
    }
  }
  .align_space-around {
    .column-wrapper__inner,
    .special-icon,
    .column-content {
      justify-content: space-around;
    }
  }

  .row {
    margin-bottom: 3.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.column-wrapper,
.element-appender__inner {
  &__inner {
    height: 100%;
    display: flex;
    .column-content {
      width: 100%;
    }
  }
  .special-icon {
    margin-right: 0.75rem;
    margin-bottom: 0px !important;
  }
  .actions-controller {
    &__wrapper {
      margin-bottom: 1.25em;
      margin-top: 0.65em;

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  // comment kept in case it messes up something else   
  // .rich-text__inner > h2:last-child {
  //   margin-bottom: 2em;
  // }
}

.element-appender__inner {
  & > .container-row.container-md.row {
    padding-left: 1.1rem;
    padding-right: 1.1rem;

    & > .col-sm-12 {
      padding: 0;
    }
  }
}
