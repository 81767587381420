/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.icon__element {
  .icon-outline {
    border-radius: 50%;
    display: inline-flex;
    padding: 15px;
    .icon-provider {
      margin: 5px;
    }
  }
  span.icon-provider {
    line-height: 1em;
  }
  i.icon-provider {
    line-height: 0;
  }
}
