.general-settings {
    .ui__white-round-wrapper {
        height: calc(100% - 25px);
    }

    .ui__white-round-wrapper-half {
        height: calc(50% - 25px);
    }

    .answers-by-voice-message{
        .flex-label, .image-thumb{
            margin: 0 6%;
            label{
                font-size: 14px;
            }
        }
    }

    &__expert-mode-switch {
        margin-bottom: 1em;
        display: block;
    }
}

.social-proof-builder {
    .ant-avatar {
        padding: 5px;
        box-shadow: $circle-box-shadow;
    }
}

.profile-image-selector {
    padding: 26px 25px;

    .ultimate-image-picker__trigger button,
    .image-upload__trigger button,
    .ant-avatar {
        margin: 0 auto !important;
        display: table !important;
    }

    .ant-avatar {
        margin-bottom: 0.5em !important;
    }
}

.logo-selector {
    flex-direction: column;
    display: flex;
    align-items: center;

    &__preview {
        margin: 0 auto 0.65em !important;
        display: flex;
        align-items: center;
        max-height: 64px;
        max-width: 200px;
    }

    img {
        display: block !important;
        margin: 0 auto;
        max-height: 64px;
        max-width: 200px;
        margin-bottom: 15px;
    }
}
