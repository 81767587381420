.font-settings {
  &__delete {
    display: none !important;
  }

  &__select-element {
    &:hover {
      .font-settings {
        &__delete {
          display: block !important;
          color: $red;

          @include defaultOpacityHover(0.5);
        }
      }
    }
  }
}
