/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
$paddingS: 10px;
$paddingM: 60px;
$paddingL: 120px;

.container {
  &__padding {
    &-s {
      &-top {
        padding-top: $paddingS;
      }

      &-bottom {
        padding-bottom: $paddingS;
      }
    }

    &-m {
      &-top {
        padding-top: $paddingM;
      }

      &-bottom {
        padding-bottom: $paddingM;
      }
    }

    &-l {
      &-top {
        padding-top: $paddingL;
      }

      &-bottom {
        padding-bottom: $paddingL;
      }
    }
  }
}
