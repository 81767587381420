.job-builder-container {
  width: 100%;
  height: 100%;
  display: block;
  padding-top: '40px';
  overflow-y: scroll;
  margin-top: 25px;
  .job-builder {
    width: 100%;
    margin: 40px;
  }
}

.header-view-changer {
  display: flex;
  gap: 10px;
  height: 100%;

  .preview-size-icon {
    font-size: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b5bad8;
    cursor: pointer;

    &.active {
      color: $primary-color;
      transition: font-size 0.1s;
      background: #e6e6e699;
      cursor: auto;
      width: 35px;
    }
  }
}

.job-builder-header {
  border-bottom: 1px solid rgb(227 227 227);
  position: fixed;
  left: 515px;
  right: 0px;
  top: 64px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  z-index: 15;
}
