.lead-qualifier-builder {
    .sortable-list {
        .ant-form-item {
            margin-bottom: 0;

            input {
                padding: 0 !important;
                background: initial !important;

                &:focus {
                    border-color: inherit;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
            }

            &-control-input {
                min-height: initial !important;
            }
        }
    }

    &__form {
        .ui__scroll-wrapper {
            padding: 0 15px;
        }
    }

    &__question,
    &__choice {
        .ant-input {
            background: none;
            border: none;
        }
    }

    &__question {
        opacity: 0.25;
        cursor: pointer;

        padding: 20px 25px 40px;

        &.active {
            opacity: 1;
            cursor: initial;
        }

        &-header {
            position: relative;
            padding: 0 0 18px;

            .ant-form-item {
                margin-bottom: 0 !important;
                width: 100%;

                input {
                    font-weight: 500 !important;
                }
            }

            &-icon-circle {
                position: relative;
                z-index: 2;
                margin-right: 15px;
            }

            &:hover {
                background: $light-grey;
            }
        }

        &__choice {
            position: relative;
            padding: 8px 30px 8px 50px;

            .ant-form-item {
                margin-bottom: 0;

                input {
                    font-weight: 300 !important;
                    padding: 0 !important;
                    background: initial !important;
                }

                &-control-input {
                    min-height: initial !important;
                }
            }

            .anticon {
                cursor: pointer;
            }

            &:hover {
                background: $light-grey-medium;
            }

            .ultimate-image-picker {
                margin-right: 10px;
            }
        }

        &.lq-red {
            .lead-qualifier-builder__question-header-icon-circle {
                background: $red;
            }

            .lead-qualifier-builder__divider-line {
                background: $red;

                &:before {
                    background: $red;
                }
            }
        }

        &.lq-yellow {
            .lead-qualifier-builder__question-header-icon-circle {
                background: $yellow;
            }

            .lead-qualifier-builder__divider-line {
                background: $yellow;

                &:before {
                    background: $yellow;
                }
            }
        }

        &.lq-blue {
            .lead-qualifier-builder__question-header-icon-circle {
                background: $primary-color;
            }

            .lead-qualifier-builder__divider-line {
                background: $primary-color;

                &:before {
                    background: $primary-color;
                }
            }
        }

        &.lq-blue-secondary {
            .lead-qualifier-builder__question-header-icon-circle {
                background: $secondary-color;
            }

            .lead-qualifier-builder__divider-line {
                background: $secondary-color;

                &:before {
                    background: $secondary-color;
                }
            }
        }

        &.lq-orange {
            .lead-qualifier-builder__question-header-icon-circle {
                background: $orange;
            }

            .lead-qualifier-builder__divider-line {
                background: $orange;

                &:before {
                    background: $orange;
                }
            }
        }

        &.text,
        &.range {
            .lead-qualifier-builder__question-header {
                padding-bottom: 0;

                .lead-qualifier-builder__divider-line {
                    display: none;
                }
            }
        }
    }

    &__divider-line {
        width: 1px;
        height: 100px;
        background: $primary-color;
        position: absolute;
        top: 0;
        left: 18px;
        z-index: 1;
        height: 100%;

        &:before {
            content: " ";
            width: 7px;
            height: 7px;
            top: 16px;
            background: $primary-color;
            position: absolute;
            left: -3px;
            border-radius: 50px;
        }
    }

    .sortable-list__item:last-child {
        & > .lead-qualifier-builder__question {
            & > .lead-qualifier-builder__divider-line {
                height: 15px;
            }
        }
    }

    @media (max-width: 1640px) {
        .form-group-inline {
            flex-wrap: wrap;

            .ant-form-item {
                flex: 1 0 33% !important;

                &:last-child {
                    margin-left: 0 !important;
                }
            }
        }
    }
}

.lq-question {
    &.sortable-list {
        &__item {
            margin: 25px 0 30px;

            &:first-child {
                margin-top: 0;

                .lead-qualifier-builder__question {
                    margin-top: 0 !important;
                }
            }

            &__action-bar {
                position: absolute;
                top: 22px;
                left: initial;
                right: 35px;
                width: 15px !important;
                height: 40px !important;
                display: flex;
                align-items: center;
                color: $red;
            }
        }
    }
}

.lq-choice {
    &.sortable-list {
        &__item {
            &__action-bar {
                position: absolute;
                top: 0;
                left: initial;
                right: 15px;
                width: 20px !important;
                height: 100% !important;
                display: flex;
                align-items: center;
                color: $red;
            }
        }

        &__add {
            .ui__icon-circle {
                width: 23px !important;
                min-width: 23px !important;
                height: 23px !important;
                margin: 0px 0 0 7px !important;
                background: $primary-color;
            }

            &.lq-red {
                .ui__icon-circle {
                    background: $red;
                }
            }

            &.lq-orange {
                .ui__icon-circle {
                    background: $orange;
                }
            }

            &.lq-yellow {
                .ui__icon-circle {
                    background: $yellow;
                }
            }

            &.lq-blue {
                .ui__icon-circle {
                    background: $primary-color;
                }
            }

            &.lq-blue-secondary {
                .ui__icon-circle {
                    background: $secondary-color;
                }
            }
        }
    }
}

.typeselector-list {
    &__option {
        display: flex;
        align-items: center;
        cursor: pointer;

        .ui__icon-circle {
            margin-right: 10px;

            &.lq-red {
                background: $red;
            }

            &.lq-orange {
                background: $orange;
            }

            &.lq-yellow {
                background: $yellow;
            }

            &.lq-blue {
                background: $primary-color;
            }

            &.lq-blue-secondary {
                background: $secondary-color;
            }
        }

        &:hover {
            background: $light-grey;
        }
    }
}

.ant-popover-content {
    .ant-popover-inner-content {
        padding: 0 !important;
    }
    .typeselector-list__option {
        padding: 8px 12px;
    }
}

.lq-question,
.lq-choice {
    .sortable-list__item-handle {
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: 3;
        opacity: 0;
        left: 10px;
    }
}

.lq-question {
    .sortable-list__item-handle {
        top: 17px;
        left: 24px;
    }
}

.lq-choice {
    .sortable-list__item-handle {
        top: 0;
        left: 0;
    }
}
