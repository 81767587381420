.info-container {
  text-align: left;
  margin-bottom: 3rem;
}

.main-title {
  font-size: 1.5em;
  color: $color-high-contrast !important;
  margin-bottom: 1em !important;
  font-weight: 600;
  max-width: 800px;
  @include media('<sm') {
    margin-top: 10%;
  }
}

.info-box {
  border-radius: 20px;
  padding: 30px;
  max-width: 800px;
  background-color: #21242e;
}

.info-line {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.info-text {
  line-height: 28px;
  font-weight: 300;
  font-size: 18px;
  color: white;
  margin-bottom: 0;
}

.info-title {
  font-weight: 600;
  font-size: 20px;
  color: white;
  margin-top: 20px;
}

.info-icon {
  color: black;
  height: 20px;
  width: 20px;
  background-color: #52ca2e;
  border-radius: 18px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 15px;
  margin-top: 5px;
}

@media only screen and (max-width: 920px) {
  .info-box {
    width: auto;
  }
}
