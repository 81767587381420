/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.html-widget {
  &__icon-on-empty-view {
    svg {
      font-size: 36px;
      color: $primary-color;
    }
  }
}
