.job-validation-modal {
  .ant-modal-body {
    padding-inline: 0px !important;
  }
  .form-section {
    margin-block: 20px;
  }
  .settings-title {
    @extend .settings-title;
  }
  .options-picker {
    @extend .options-picker;
  }
  .element-card {
    @extend .element-card;
  }
  h6 {
    font-size: 1em !important;
  }
}
