.default-empty {
    &.ant-empty {
        padding: 90px;

        .ant-empty-footer {
            max-width: 650px;
            margin: 25px auto 0 !important;

            h2 {
                line-height: 1em !important;
            }
        }

        .ant-empty-image {
            height: initial !important;

            svg {
                font-size: 85px;
                color: #ebebeb;
            }
        }

        @include breakpoint(max_md) {
            & {
                padding: 20px;

                .ant-empty-image {
                    svg {
                        font-size: 80px;
                    }
                }
            }
        }
    }
}
  