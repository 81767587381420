.ad-on-subscription-modal {
  .carousal-styles,
  [id^='react-player-wrapper-'] {
    width: 100% !important;
    color: #fff;
    line-height: 300px;
    text-align: center;
    background-color: #364d79;
    border-radius: 10px;
    margin: 20px 0px;
    object-fit: contain;
  }

  .ant-drawer-content-wrapper {
    width: 720px !important;

    @include breakpoint(max_sm) {
      width: 95% !important;
    }
  }

  .ant-drawer-content {
    overflow: visible !important;
  }

  h4.ant-typography {
    font-size: 1em;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 0px;
  }

  .subscription-top-bar {
    display: flex;
    justify-content: space-between;

    .is-active-tag {
      height: fit-content;
      margin-left: 10px;
      margin-top: 12px;
      border: none !important;
      align-self: baseline;
    }

    .title-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .title {
        font-size: 1.25em;
        font-weight: 600;
      }

      .price {
        font-size: 1.25em;
        font-weight: 600;
      }
    }

    .left-side {
      max-height: 70px;
      
      .icon-wrapper-with-shadow-60 {
        margin-right: 20px;
      }

      &,
      &__inner {
        display: flex;
      }

      &__inner {
        align-items: center;
      }
    }

    .right-side {
      text-align: end;
    }
  }

  .ads-included {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 15px;
  }

  .ad-on-details {
    a {
      color: black;
      text-decoration: underline;
      font-weight: 600;
    }
    margin-top: 15px;
  }

  .ad-on-carousel-dots {
    bottom: -12px !important;
    .slick-active {
      button {
        background: $primary-color !important;
      }
    }
    button {
      background: $primary-color !important;
    }
  }
}
.ad-on-bold {
  font-weight: 600;
}

.benefits-list {
  display: flex;
  h4 {
    margin-left: 10px;
  }
}

.success-check-icon {
  font-size: 115px;
  background: #c4ffc6;
  padding: 25px;
  border-radius: 50%;
}

.benefit-list {
  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    h4 {
      margin-left: 5px;
    }
    &-cross-icon {
      min-width: 20px;
    }
  }
}
