.learning-center {
  margin: 0 auto;

  h1 {
    margin: 0 !important;
  }

  h4 {
    color: $secondary-text-color !important;
    font-weight: 300 !important;
    margin: 0.25em 0 !important;
  }

  &__header {
    margin-bottom: 2.25em;

    &__content {
      margin-bottom: 1.5em;
    }
  }

  .ant-card-body {
    padding: 24px;
  }

  @include breakpoint(max_575) {
    .ant-card-body {
      padding: 15px 25px;
    }
  }

  .overlay-empty {
    padding: 20px;

    h2 {
      margin-bottom: 0.15em !important;
    }
  }

  label {
    font-weight: 600;
    color: #000;
  }
}

.video-module-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;

  @include breakpoint(max_md) {
    grid-template-columns: repeat(1, 1fr);
  }

  .ant-card {
    height: 100%;
    .ant-card-body {
      height: 100%;
    }
  }

  a {
    transition: all 0.3s;
    transform: translateY(0) !important;
    border-radius: 8px !important;

    &:hover {
      box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.1) !important;
      transform: translateY(-5px) !important;
    }
  }
}

.video-module-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__info {
    height: 24px;
  }

  &__badge {
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
  }

  &__caption {
    font-size: 13px;
    line-height: 24px;
    &__module {
      color: #1f2968;
    }
    &__duration {
      color: #3d3d3d;
    }
  }

  &__title {
    font-size: 16;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
    color: #05070f;
  }

  &__subtitle {
    font-size: 14;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 10px;
    color: #5e5e60;
  }

  &__progress {
    height: 20px;
    margin-top: 1px;
    margin-bottom: 1px;

    .ant-progress {
      .ant-progress-text {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.motivation-banner {
  background: linear-gradient(
      258.51deg,
      rgba(255, 255, 255, 0.5) -41.48%,
      rgba(0, 8, 0, 0.5) 116.32%
    ),
    #070101 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-card-body {
    display: flex;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
    align-items: center;
  }

  &__text {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    padding: 50px;

    &__title {
      color: #fff;
      font-size: 24px;
      line-height: 24px;
    }

    &__subtitle {
      color: #eeeeee;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__vector {
    flex-basis: 50%;
    display: flex;
    align-items: end;
    justify-content: end;
    height: 235px;

    img {
      height: 100%;
      padding: 20px 50px 0 0;
      filter: drop-shadow(0px 4px 100px rgba(255, 255, 255, 0.25));
    }
  }

  @include breakpoint(max_lg) {
    align-items: flex-end;

    &__vector {
      img {
        padding: 10px 30px 0 0;
      }
    }
  }

  @include breakpoint(max_667) {
    &__text {
      flex-basis: 100% !important;
    }

    &__vector {
      display: none;
    }
  }
}

.module-completion-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__completion-badge {
    height: 192px;
    width: 196px;
  }

  &__title {
    font-size: 28px;
    color: #000000;
    text-align: center;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    color: #000000af;
    text-align: center;
    margin-top: 12px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    margin-top: 18px;

    &__left {
      border: 1px solid #dddddd !important;
      border-radius: 6px !important;
      padding: 12px 20px;
    }

    &__right {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 20px;
      column-gap: 12px;
      border: none !important;
      color: #fff !important;
      background: linear-gradient(90deg, #5969cc 6.19%, #33c2c3 117.08%) !important;
      border-radius: 6px !important;
    }
  }
}

.module-completion-banner {
  background: linear-gradient(93.75deg, #cfd5ff -6.45%, #d4ffff 102.96%) !important;

  .ant-card-body {
    padding: 9px 24px !important;
  }

  &__inner {
    display: flex;
    align-items: center;
    column-gap: 12px;

    &__badge {
      height: 50px;
      width: 50px;
    }

    @include breakpoint(max_667) {
      &__badge {
        display: none !important;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24.51px;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 13px;
        line-height: 17.7px;
        color: #677074;
      }
    }

    &__progress {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      row-gap: 4px;

      &__vector {
        display: none;
      }

      @include breakpoint(max_667) {
        &__vector {
          display: block !important;
        }
      }

      .ant-progress {
        .ant-progress-inner {
          .ant-progress-text {
            div {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 15px;
              color: #596acc99;
              .imp {
                color: #596acc;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.video-accordion {
  .ant-collapse {
    &-borderless {
      background-color: initial !important;
    }

    &-header {
      padding: 18px 16px !important;
      padding-left: 0 !important;
      border-left: 1px solid $form-border-grey;

      .video-panel__title {
        font-size: 1.2em;
        font-weight: 500;
        margin-left: -15px;
        display: flex;

        label.checkcircle {
          display: inline-block;
          width: 30px;
          height: 30px;
          vertical-align: middle;
          margin-top: -3px;
          margin-right: 15px;
        }
      }
    }

    &-content-box {
      border-left: 1px solid $form-border-grey;
    }

    &-item:first-child .ant-collapse-header {
      padding-top: 0 !important;
    }

    &-item:last-child {
      border-bottom: 0 !important;

      .ant-collapse-header {
        padding-bottom: 0 !important;
      }
    }

    @include breakpoint(max_575) {
      &-header {
        padding: 18px 0 !important;
      }

      &-arrow {
        right: 0;
      }
    }
  }

  .video-panel {
    &__description {
      padding-top: 0.5em;
      margin-left: 2em;

      @include breakpoint(max_575) {
        margin-left: 0;
      }
    }
  }
}

.react-16-9-player {
  width: 100% !important;
  height: 100% !important;
  position: relative !important; // Set to relative
  padding-top: 56.25% !important; // Percentage ratio for 16:9

  > div {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
  }
}

.react-player-loader-wrapper {
    width: 100% !important;
    position: relative !important; // Set to relative
    span {
        z-index: 9 !important;
    }
}

.noshow-player {
  height: 0 !important;
  width: 0 !important;
}

.module-videos-container {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 24px 18px;
  margin-left: 20px;
  background-color: #f0f0f0;
  height: 100%;

  @include breakpoint(max_md) {
    margin-left: 0 !important;
  }

  .module-videos-inner {
    &__description {
      &__title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }

      &__details {
        display: flex;
        align-items: center;
        column-gap: 12px;
        color: #646464;
        font-size: 13px;
        line-height: 20px;

        &__progress {
        }

        &__duration {
        }

        &__tag {
          background-color: #fbd5e2;
          color: #671e36;
          font-size: 14px;
          display: flex;
          align-items: center;
          letter-spacing: 0.2px;
          border-radius: 6px;
          padding: 4px 8px;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      margin-top: 12px;

      .active-video {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        scale: 1.02;
      }

      .locked-video {
        cursor: not-allowed;
        filter: blur(1.5px) !important;
      }

      &__video {
        transition: all 0.2s ease-in-out;
        display: flex;
        column-gap: 12px;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid rgba(234, 234, 234, 1);
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
        padding: 12px 16px 12px 16px;
        background-color: #f8f9fa;

        &__info {
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          color: rgba(100, 100, 100, 1);
          font-size: 12px;
          line-height: 20px;

          &__title {
            font-size: 14px;
            color: rgba(30, 30, 30, 1);
          }

          &__details {
            display: flex;
            align-items: center;
            column-gap: 8px;

            &__points {
              display: flex;
              align-items: center;
              column-gap: 8px;
            }
          }
        }
      }
    }

    .bullet {
      height: 5px;
      width: 5px;
      background-color: #e0e0e0;
      border-radius: 50%;
    }
  }
}

.locked-module {
  cursor: not-allowed;
}

.allowed-module {
  cursor: pointer;
}
