.collapse-container {
  background-color: transparent !important;
  border-radius: 10px !important;
  border: none !important;

  & .ant-collapse-item {
    color: white;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600;
    background-color: #1f2124;
    border-radius: 5px !important;
    border: 0px !important;
    margin-bottom: 15px;

    & .ant-collapse-header {
      padding:  15px 20px !important;
      background-color: transparent;
      font-weight: 500;
      color: white !important;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      & .ant-collapse-arrow {
        position: unset !important;
        text-align: right !important;
        flex: auto;
        font-size: 16px !important;
        transform: translateY(-2px) !important;
      }
    }

    &.ant-collapse-arrow-active .ant-collapse-arrow > span {
      transform: rotate(-90deg) !important;
    }

    & .ant-collapse-content {
      background-color: transparent;
      border: none;
      border-radius: 10px;
      color: white;
      width: 95%;
      white-space: break-spaces !important;

      & > .ant-collapse-content-box {
        padding: 0px !important;
      }
    }
  }

  &__content {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #b5b4b4;
    padding: 0px 20px 12px 20px !important;
  }
}
