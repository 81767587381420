.contract-modal {
  &__document {
    margin: 15px 0;
  }
  
  &__controlls-wrapper {
    border: 1px solid $default-grey;
    padding: 3px 10px;
  }

  &__contract-wrapper {
    background: $default-grey;
    border-bottom: 1px solid $default-grey;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: $default-grey;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #6d6d6d;
    }
  }
}
