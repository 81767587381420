.progress-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 40%;
  margin: 0 auto;

  @include media('<sm') {
    max-width: 45%;
  }

  &__title {
    font-size: 0.75em;
    text-align: center;
    color: white;
    min-width: 130px;

    @include media('<sm') {
      font-size: 1em;
      min-width: unset;
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40%;
    align-items: center;
  }

  &__step {
    flex-grow: 1;
    height: 25%;
    margin: 0 5px;
    background-color: #343541;
    border-radius: 10px;
    transition: background-color 0.3s ease;

    &--active {
      background-color: $green;
    }

    @include media('<sm') {
      margin: 0 2px;
    }
  }
}
