.content-builder {
    max-width: 860px;
    margin: 35px auto;
    padding-top: 50px;
    padding-bottom: 50px;

    &__fixed-button {
        text-align: center;

        p.small-text {
            margin-top: 1em;
            margin-bottom: 0;
        }

        .default-cta-button {
            border-radius: 8px;
            padding: 0.775em;
            margin: 0 auto 0.35em;
            color: #fff;
            width: 90%;
            max-width: 400px;
            border: none;
            display: table;
            margin: 40px auto 0;
            line-height: 1.5;

            span {
                cursor: text;
            }

            i.icon-provider.FiArrowRight {
                vertical-align: sub !important;
                margin-left: 0.25em;
            }

            [contenteditable] {
                outline: 0px solid transparent;
            }
        }
    }

    &__logo {
        max-width: 230px;
        max-height: 70px;
        display: block;
        margin: 0 auto 40px;
    }
}

#_cbhtml {
    [data-title="Hyperlink"], [data-title="300px"] {
        display: none;
    }
    .quickadd {
        transform: translateX(7px);

        button {
            &.add-quote,
            &.add-twobutton,
            &.add-youtube,
            &.add-video,
            &.add-map,
            &.add-table,
            &.add-social,
            &.add-code,
            &.add-preformatted,
            &.add-button {
                display: none;
            }
        }
    }
}

button.row-add-initial {
    padding: 25px !important;
    height: inherit !important;
}

.is-rowadd-tool {
    border-bottom: 1px solid $ant-primary-color !important;

    button {
        top: -19px !important;
        left: calc(50% - 25px) !important;
        background: $ant-primary-color !important;
        border: none !important;

        svg {
            width: 35px !important;
            height: 35px !important;
            fill: #fff !important;
        }
    }
}

.is-tool.is-row-tool .row-more {
    background: $default-grey !important;
}

.is-elementrte-tool,
.is-rte-tool {
    left: 35px !important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04) !important;
    border: 1px solid #eee !important;
    border-radius: 8px !important;
}
