.csv-upload-modal {
  .ant-steps-item-finish .ant-steps-item-icon {
    background: linear-gradient(white, white) padding-box, $secondary-gradient border-box;
    border: 1px solid transparent;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: $gradient-green-dark;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background: $secondary-gradient;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: $secondary-gradient padding-box, $secondary-gradient border-box;
    border: 1px solid transparent;
  }

  .ant-modal-header, .ant-modal-footer{
    padding: 20px 24px;
  }
  
  .ant-modal-close {
    padding: 4px;
  }

  .submit-tab {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 300px;

    .funnel-selection-img {
      width: 100%;
      height: auto;
    }
  }
  .default-trigger {
    padding: 0;
    border: none;
  }
  .status-selector {
    width: 50%;
    max-height: 300px;
    overflow: auto;
  }
  .ant-select {
    width: 150px;
  }
  .map-fields {
    margin: 35px 20px 0px;
    .ant-row {
      label {
        margin-bottom: 0px;
      }
    }

    .ant-form-item {
      border-top: none;
      margin-bottom: 0px;
    }
  }

  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: none;
  }

  .ant-upload {
    padding: 35px;
  }
}
