.builder {
  &__content {
    .append-new-container {
      &__wrapper {
        padding: 0.5em 0;
      }
      &__arrow {
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
      }
    }
    .downArrowWrapper {
      display: flex;
      justify-content: center;
    }

    .bounce {
      -moz-animation: bounce 1.5s infinite;
      -webkit-animation: bounce 1.5s infinite;
      animation: bounce 1.5s infinite;
    }
    @-moz-keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
      }
      40% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
      }
      60% {
        -moz-transform: translateY(-15px);
        transform: translateY(-15px);
      }
    }
    @-webkit-keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
      }
      60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
      }
    }
    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
      }
      60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
      }
    }
  }
}
