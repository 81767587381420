.emoji-mart {
    border: 0 !important;

    &-preview {
        height: 40px !important;

        &-emoji {
            display: none !important;
        }
    }

    &-scroll {
        height: calc(100% - 180px) !important;
        padding: 0 !important;
    }

    &-search {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        padding: 0 !important;

        & input {
            padding: 10px 15px 10px 35px !important;
        }

        &-icon {
            top: 10px !important;
            left: 10px !important;
            right: initial !important;
        }

        &:after {
            font-family: "Open Sans", sans-serif;
            content: "Tipp: Benutze auch englische Suchbegriffe";
            color: #777;
            font-weight: 300;
            font-size: 0.9em;
        }
    }

    &-category {
        &-list {
            li {
                padding: 6px !important;
            }
        }
    }

    button, &-skin-swatch {
        cursor: pointer;
    }
}

button.emoji-mart-emoji {
    outline: none !important;
    border-radius: 50px !important;

    &:focus {
        box-shadow: 0 0 0 2pt #1890ff;
    }

    span {
        cursor: pointer !important;
    }
}
