.voice-recorder-with-textarea {
    .rhap {
        &_container {
            *:focus {
                outline: none !important;
            }

            padding: 15px;
            border-radius: $default-border-radius;
            box-shadow: $primary-box-shadow;
            max-width: 350px;
            margin: 0 auto;
            align-items: center;
            flex-direction: initial;
        }

        &_progress-section {
            flex: 10 1 auto;
        }

        &_footer {
            margin-left: 12px;
            margin-top: 0;

            .FaTrash {
                color: $red;
                cursor: pointer;
            }
        }

        &_download-progress {
            background-color: #eee;
        }

        &_progress-filled {
            background: #cccccc;
        }

        &_progress-indicator {
            width: 14px;
            height: 14px;
            margin-left: -8px;
            top: -5px;
            background: #bbb;
            box-shadow: initial;
        }

        &_time {
            font-size: 14px;
        }

        &_play-pause-button {
            font-size: 25px;
            width: 25px;
            height: 25px;
            color: #bbb;
        }
    }
    &__player {
        &-enter {
            opacity: 0;
            transform: scale(0.9);
        }
        &-enter-active {
            opacity: 1;
            transform: translateX(0);
            transition: opacity 0.75s, transform 0.75s;
        }
        &-exit {
            opacity: 1;
        }
        &-exit-active {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 0.75s, transform 0.75s;
        }
    }
}

.mic {
    display: inline-block;
    opacity: 0;
    color: $red;
    font-size: 1.35em;
}

.mic.active {
    animation: flash 1.5s linear 0s infinite normal;
    display: inline-block;
    opacity: 1;
}

.mic.cancel {
    animation: turn 0.75s linear 0s;
}

.recording-buttons-wrapper {
    position: relative;
    background: #fff;
    height: 46px;
    border-radius: 0 0 0.25rem 0.25rem;
    margin-top: -8px;
    margin-bottom: 3px;
    border: 1px solid #ced4da;
    border-top: 0;
    padding-top: 6px;

    .swipe-to-cancel-wrapper {
        position: absolute;
        right: 8px;
    }

    .mic {
        position: absolute;
        left: 8px;
        top: 0px;

        i {
            font-size: 1.25em;
        }
    }
}

.swipe-to-cancel-wrapper {
    width: 300px;

    .slide-to-cancel {
        position: absolute;
        right: 75px;
        top: 8px;
        color: #777;
        font-size: 0.75em;
    }

    .circular-icon-button {
        position: absolute;
        right: 0;
        cursor: pointer;

        i {
            color: #FFF;
        }
    }
}

@keyframes flash {
    50% {
        opacity: 0;
    }
}

@keyframes turn {
    82% {
        transform: translateY(-30px) rotate(360deg);
        opacity: 1;
    }
    100% {
        transform: translateY(0) rotate(360deg);
        opacity: 0;
    }
}
