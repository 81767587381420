.business-hours {
  .ant-picker-range {
    margin-bottom: 10px;
    width: 100%;
  }

  & .day {
    display: flex;
    border-bottom: 1px solid $light-grey;
    padding: 0.65em 0;

    & > * {
      flex: 1;
    }

    label,
    span {
      font-weight: 200;
    }

    & label {
      margin-bottom: 0;
    }

    & .divider {
      color: #999;
      padding: 0 5px;
    }

    & .ant-picker-input {
      width: 60px;
      text-align: center;
    }

    & .anticon-plus-circle,
    & .anticon-delete {
      cursor: pointer;
      transition: ease opacity 0.5s;
    }

    & .anticon-plus-circle {
      color: $green;
      float: right;
      text-align: right;
      margin: 3px 0 0;
    }

    & .anticon-delete {
      color: $red;
      margin: 0 0 0 5px;
      opacity: 0;

      @include breakpoint(max_sm) {
        opacity: 1;
      }
    }

    & .time-unit {
      margin-bottom: 5px;

      &.edit {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover .anticon-delete {
        opacity: 1;
        transition: ease opacity 0.5s;
      }
    }
  }
}
