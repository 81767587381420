.icon-overlay-on-hover {
    position: relative;
    overflow: hidden;
    min-width: 60px;
    min-height: 60px;
    cursor: pointer;

    &:hover > * {
        opacity: 0.35;
    }

    &__icon,
    &__icon:hover,
    & > *,
    & > *:hover {
        transition: ease all 0.5s;
    }

    &__icon {
        opacity: 1 !important;
        position: absolute;
        top: 200%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        font-size: 3em;
    }

    &:hover &__icon {
        top: 50%;
    }
}

.circular-wrapper {
    overflow: hidden;
    border-radius: 50%;

    &-80 {
        width: 80px;
        height: 80px;
    }

    &__preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.circular-image-upload {
    margin: 0 auto 24px;
    display: table;
}
