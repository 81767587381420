.undo-redo-buttons {
    .ant-btn-circle {
        width: 20px !important;
        min-width: 20px !important;

        svg {
            vertical-align: middle;
        }
    }

    .ant-btn-link {
        color: $primary-color;

        &:disabled {
            opacity: 0.2;
        }
    }
}
