.image-upload {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
  }

  &__upload-field {
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease, height 0.5s ease, width 0.5s ease;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05);
  }

  &__upload-button {
    max-height: 180px;
    border-radius: 8px;

    .ant-upload.ant-upload-drag {
      border-radius: 8px;
      padding-left: 10px;
      padding-right: 10px;
    }

    span {
      height: 100%;
      width: 100%;
    }
  }

  &__drag-area {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    .ant-upload.ant-upload-drag {
      border-radius: 8px;
    }
    span {
      height: 100%;
      width: 100%;
    }
  }

  &__list-item {
    grid-column: span 1;
  }
  &__modal {
    .ant-modal-body {
      height: initial !important;
    }
  }
  &__preview {
    text-align: center;
    margin: 20px auto;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 10px;
    max-width: 500px;

    img {
      max-width: 100%;
      display: block;
      margin: 0 auto 10px;
    }

    .ant-btn {
      margin-right: 8px;
    }
  }

  &__modal {
    .ant-modal-body {
      position: relative;
      height: 350px;
    }
  }

  &__input {
    margin-top: 8px;
    position: relative;
    overflow: hidden;

    &-wrapper {
      max-width: 500px;
      margin: 0 auto;

      .ant-empty {
        margin: 0 8px 40px;
      }
    }

    input {
      height: 25px;
      padding-left: 8px !important;
      margin-top: -2px;

      &:focus {
        outline: none !important;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    label {
      cursor: pointer;
      position: absolute;
      left: 36px;
      background: $light-grey;
      border: 1px solid $form-border-grey;
      height: 33px;
      vertical-align: middle;
      display: flex;
      align-items: center;
      top: 0;
      padding: 0 8px;

      &:hover {
        background-color: $default-grey;
      }
    }
  }

  &__cancel-cropping {
    margin: 10px auto 0;
    display: table;

    button {
      margin-right: 8px;
    }
  }
  &__container {
    .ant-tooltip {
      display: none;
    }
  }
}

.icon-size {
  font-size: 30px !important;
}
