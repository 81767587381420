.packages-wrapper {
  text-align: center;

  h1,
  h2 {
    line-height: 1em;
  }

  .packages {
    margin-top: 130px;
  }

  .package {
    padding: 30px 25px;
    text-align: left;

    &__highlighted {
      margin-top: -47px;

      .package-image {
        max-width: 190px;
      }
    }

    &__green-badge {
      display: flex;
      max-width: 175px;
      padding: 2px 12px;
      border-radius: 20px;
      background-color: #77c221;
      background-image: linear-gradient(171deg, #a2eb50, #77c221);
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-image {
      width: 100%;
      max-width: 160px;
      margin: 0 auto 50px;
      display: table;
      
      @include breakpoint(max_440) {
        margin: 30px auto 50px;
      }
    }

    &-features {
      list-style-type: none;
      margin: 2em 0;

      &-item {
        cursor: help;
        margin: 1em 0;
        display: flex;
        align-items: center;

        &__icon {
          margin-right: 0.5em;
        }

        &.excluded {
          opacity: 0.35;
          text-decoration: line-through;
        }
      }
    }

    h1 {
      margin-bottom: 0em;
      margin-top: 0.5em;
      font-size: 2.5em;
    }

    button {
      width: 100%;
      margin-top: 1em;
    }

    .support-info {
      margin-bottom: 50px;
    }

    .anticon-check-circle {
      color: $green;
      margin-right: 0.25em;
    }
  }

  .ant-alert-info {
    margin-bottom: 0 !important;
  }

  .support-info__label {
    display: inline-block;
    margin-right: 0.5em;
  }

  .badge {
    border: 1px solid $light-border-grey;
    border-top: 0px;
    border-right: 0px;
    background: $light-grey;
    max-width: 100px;
    color: $primary-color;
    padding: 0.5em 1.25em;
    font-weight: 500;
    position: absolute;
    right: 0;
    font-size: 0.9em;
    top: 0px;
    border-radius: 0 0 0 8px;
  }

  .horizontal-list-of-logos {
    margin: 30px 0 60px;
  }

  .individual-package {
    margin-top: 24px;

    .anticon.anticon-tool {
      font-size: 5em;
    }

    &__image {
      max-width: 210px;

      @include breakpoint(max_440) {
        padding-bottom: 25px !important;
        margin: 0 auto !important;
        display: table;
      }
    }
  }

  @include breakpoint(max_440) {
    padding: 0 !important;
  }

  @include breakpoint(max_md) {
    .packages {
      margin-top: 60px;
    }

    .package {
      margin-bottom: 1.5em;
      padding: 0;

      &__highlighted {
        margin-top: 15px;

        .package-image {
          max-width: 160px;
        }
      }
    }

    .individual-package {
      margin-top: 0;

      .anticon.anticon-tool {
        margin-bottom: 0.15em;
      }
    }
  }
}

.horizontal-list-of-logos {
  display: flex;
  align-items: center;
  margin: 30px 0 40px;
  list-style: none;

  &.centered {
    justify-content: center;
  }

  li {
    margin-right: 35px;

    @include breakpoint(max_md) {
      margin-right: 20px;
    }

    img {
      height: 30px;

      @include breakpoint(max_md) {
        height: 22px !important;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
