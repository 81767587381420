/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.loading__element {
  text-align: center;

  span.icon-provider {
    line-height: 1em;
  }

  i.icon-provider {
    line-height: 0;
  }

  & .loading-wrapper {
    position: relative;
    display: inline-block;

  }

  & .spinner {
    height: 200px;

    & .light-circle {
      stroke: #E4E9F0;
    }

    & .circle {
      stroke-dasharray: 502.72540283203125;
      stroke-dashoffset: 502.72540283203125;
      stroke-linecap: round;
      transition: stroke-dashoffset 0.4s ease-in-out, stroke-dasharray 0.4s ease-in-out;
      animation: stroke-dasharray .4s linear;
    }

    & .check {
      stroke-dasharray: 123.8634262084961;
      stroke-dashoffset: 123.8634262084961;
      transition: stroke-dashoffset 0.3s ease-in-out, stroke-dasharray 0.3s ease-in-out;
      animation: all .4s linear;
    }
  }
}
