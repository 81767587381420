@import 'Common.scss';

.builder__settings-sidebar__container {
  .ant-tag {
    margin-bottom: 8px;
  }
  &__simple-title {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $form-border-grey;
    width: 100%;
    font-size: 1.1em;
    font-weight: 500;
  }

  &__template-tags {
    @extend .template-tags;
  }

  &__empty-tags-list {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
  }

  .ant-tabs-bar {
    margin: 0 0 8px 0;
  }

  & > .settings-card {
    .ant-card-head-wrapper {
      padding-top: 8px;
    }
  }

  .settings-card {
    box-shadow: none;

    .ant-card-head {
      min-height: initial !important;

      &-wrapper {
        padding-bottom: 8px;
      }

      &-title {
        font-size: 1.1em;
        font-weight: 500;
        padding: 0;
      }
    }

    &__no-padding {
     & > .ant-card-body {
        padding: 0 !important;

        .ant-tabs-nav-wrap {
          padding: 0 24px !important;
        }
      }
    }
  }
  .settings-title {
    @extend .settings-title;
  }
  .builder-slider-style {
    .ant-slider-dot {
      display: none;
    }
    .ant-slider-dot-active {
      background-color: $secondary-color;
    }
  }
  .form-field-common-style {
    margin: 10px 0px;
  }
  .builder__settings-sidebar__row {
    margin: 1.5em 0;

    .route-to-gradient {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      box-shadow: $circle-box-shadow;
    }

    .ant-form-item-extra {
      font-size: 0.915em;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  .helper-content {
    font-size: 12px;
    color: #ccc;
  }
  .footer-setting-card {
    .ant-card-body {
      padding-left: 0px;
      padding-right: 0px;
    }
    .builder-settings-switch {
      border: 1px solid $default-grey;
      border-left: 0px;
      border-right: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 25px;
    }
    .helper-content {
      font-size: 12px;
      color: #ccc;
    }
  }
  .builder-settings-pro-title {
    border: 1px solid $light-grey-lines;
    border-left: 0px;
    border-right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.without-border {
      border: initial;
      padding: 0 !important;
    }
  }

  .builder-settings-title__with-delete {
    .anticon-delete {
      color: $red !important;
    }
  }

  .ant-form-item-label > label,
  .switch-right,
  .ant-form-item-control-input-content > label {
    font-size: 0.97em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400 !important;
  }
  .carousel-settings {
    .sortable-container {
      margin-bottom: 10px;
    }
    .drag-handler {
      cursor: move;
    }
  }
}

.general-settings-tabs {
  height: 100%;

  .settings-subtitle {
    padding-right: 3em;
  }
}
.general-settings-tabs .ant-tabs-bar {
  padding-left: 1.5rem;
}

[contenteditable]:focus {
  padding: 2px 4px 2px 4px;
  outline: 1px solid $light-grey-lines;
  border-radius: 2px;
}

.tabs__reset-left-padding {
  .ant-tabs-bar {
    margin: 0 0 30px 0;
  }

  .ant-tabs-nav-wrap {
    padding-left: 0 !important;
  }
}

.choice-tabs-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.meta-settings {
  &__favicon {
    max-width: 65px;
    .ant-upload {
      padding: 0.4em !important;
      max-width: 65px;
      max-height: 65px;
    }
  }
}

.builder__settings-sidebar__row {
  .choice-template-setting {
    background-color: $light-grey;
    min-height: 70px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;

    img {
      margin: 0px !important;
      width: 100%;
    }

    &__active {
      border: 2px solid $secondary-color;
      box-shadow: $primary-box-shadow;
    }

    @include defaultOpacityHover(0.65);
  }

  .message-contact-number {
    .form-control {
      width: 100%;
    }
  }
}
