@import 'include-media';

$primary-color: #1e266d;
$secondary-color: #23d3d3;
$ant-primary-color: #1890ff;

// $primary-color: #E03216;
// $secondary-color: #ff8777;

$light-grey: #f9f9f9;
$light-grey-medium: #f5f5f5;
$default-grey: #ddd;
$dark-grey: #a19d9d;
$color-high-contrast: #fff;
$form-border-grey: #d9d9d9;
$light-border-grey: #eee;
$light-grey-lines: #f0f0f0;

$red: #b10505;
$dark-red: #870a0a;
$green: #0fb90f;
$border-green: #9ae59e;
$light-green: #daf8e0;
$dark-green: #0e8a0e;
$yellow: #f7d713;
$dark-yellow: #e0c418;
$orange: #ee7b28;
$dark-orange: #ec5a24;
$light-pink: #ffccd4;
$help-icon-color: #32749e;
$builder-tab-icon-color: #212b6d;
$black-background: #131313;
$medium-light-grey: #3f3f3f;
$gradient-green-light: #04fd4a;
$gradient-green-dark: #02972c;

$primary-gradient: linear-gradient(-5deg, $primary-color 0%, $secondary-color 130%);
$secondary-gradient: linear-gradient(
  127.8deg,
  $gradient-green-light 26.72%,
  $gradient-green-dark 87.04%
);
$primary-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
$circle-box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.175);
$card-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);

$primary-text-color: #333;
$secondary-text-color: #777;
$black: #000;
$font-size-mini: 0.65em;
$font-size-small: 0.85em;
$font-size-medium-small: 0.95em;
$font-size-default: 1em;
$font-size-medium: 1.25em;
$font-size-bigger: 1.35em;

$default-top-bottom-padding: 30px;

$default-border-radius: 8px;

$sidebar-width: 243px;
$header-height: 64px;

body {
  overflow-x: auto !important;
}

@mixin horizontalCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

@mixin horizontalAndVerticalCenter {
  @include horizontalCenter;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin defaultOpacityHover($opacity: 0.925) {
  &,
  &:hover {
    transition: 0.5s ease-in-out opacity;
  }

  &:hover {
    opacity: $opacity;
  }

  &__active:hover {
    opacity: 1;
  }
}

@mixin flex-vertical-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-horizontal-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meetovo-funnel-builder-popup,
.meetovo-funnel-builder-popover {
  @import '~bootstrap/scss/bootstrap';
}

.meetovo-funnel-builder-layout {
  @import '~bootstrap/scss/bootstrap';
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@import '~bootstrap/scss/bootstrap-utilities.scss';

/* For WebKit-based browsers (e.g., Safari, Chrome) */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: $light-grey-medium;
}

::-webkit-scrollbar-thumb {
  border: 3px solid $light-grey-medium;
  background-color: #ccc;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

/* For Firefox */
/* Note: Firefox does not support customizing the scrollbar width */
/* You can adjust the track and thumb colors only */
* {
  scrollbar-width: thin;
  scrollbar-color: #ccc $light-grey-medium;
}

*::-moz-scrollbar-track {
  background-color: $light-grey-medium;
}

*::-moz-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

*::-moz-scrollbar-thumb:hover {
  background-color: #999;
}

@import 'Breakpoints';
@import 'Login/styles/index';
@import 'ComingSoon/styles/index';
@import 'UI/styles/index';
@import 'Events/styles/index';
@import 'Share/styles/index';
@import 'ContractModal/styles/index';
@import 'Analytics/styles/index';
@import 'Connect/styles/index';
@import 'Marketplace/styles/index';
@import 'Funnel/styles/index';
@import 'GeneralComponents/styles/index';
@import 'BookingDummy/styles/index';
@import 'SharedUI/styles/index';
@import 'AccountOverview/styles/index';
@import 'VoiceRecorder/styles/index';
@import 'LearningCenter/styles/index';
@import 'ContentBuilder/styles/index';
@import 'CustomerLogin/styles';
@import 'FunnelDisableModal/styles/index';
@import 'Checkout/styles/index';
@import 'OnBoarding/styles/index';
@import '~meetovo-frontend-booking/src/LeadQualifier/styles/index';
@import 'HelpButton/styles/index';
@import 'TrailBanner/styles/index';
@import 'Tour/styles/index';
@import 'AgencyDashboard/styles/index.scss';
@import 'Builder/styles/index.scss';
@import 'Builder/AdBuilder/styles/index.scss';
@import 'Builder/WidgetsBuilder/styles/index.scss';
@import 'Builder/AdJobBuilder/styles/index.scss';
@import 'Library/styles/index.scss';
@import 'CustomerManagement/styles';
@import 'Builder/components/sharedUI/styles/index.scss';
