.marketplace-main-container {
  .ant-row {
    align-items: stretch;
  }

  .hoverable-connect-card {
    height: 100%;
    position: relative;
  }

  .marketplace-main-content {
    margin-top: 30px;

    .ant-card,
    .ant-card-body {
      height: 100% !important;
    }

    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .card-top {
      display: flex;
      justify-content: space-between !important;
      margin-bottom: 30px;

      h1 {
        margin-bottom: 0px !important;
      }

      .price-texts {
        display: flex;
        width: auto;
        align-items: baseline;

        .price {
          font-size: 25px;
          color: black;
          &__euro {
            font-size: 25px;
            color: grey;
          }
        }
      }
    }

    .card-bottom {
      min-height: 165px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0px 0px 0px !important;

      .ant-tag {
        height: fit-content;
        border: none;
      }
    }
  }
}

.icon-wrapper-with-shadow-60 {
  box-shadow: $primary-box-shadow;
  border-radius: 15px !important;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;

  img {
    margin: 8px !important;
    width: 45px;
  }
}
