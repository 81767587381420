.decline-applicant {
  &__email-footer {
    display: flex;
    justify-content: flex-end;
    padding-block: 12px;
  }
  &__editor {
    min-height: 300px;
    border: 1px solid $form-border-grey;
    border-radius: 2px;
    padding: 10px;
  }
  &__toolbar {
    border: 0px;
    padding: 0;

    .rdw-option-wrapper {
      padding: 15px;
    }

    .rdw-inline-wrapper {
      .rdw-option-wrapper {
        &:frist-child {
          margin-left: 0;
        }
      }
    }
  }
  .message-wrapper {
    padding: 1.5rem 2rem;
  }
  .message-card {
    padding: 20px;
    border: 1px solid #d9d9d9;
  }
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}
