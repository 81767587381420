/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.img-element {
  &__wrapper {
    padding: 2px;
    position: relative;
    .spinner__wrapper {
      position: absolute;
      top: 0px;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgb(180, 200, 209);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: larger;
      border-radius: 15px;
    }

    &__loading {
      height: 500px;
    }
  }
}
