.color-circle {
  cursor: pointer;
  display: flex;
  align-items: center;

  &__circle {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    box-shadow: $circle-box-shadow;
    border: 1px solid #ebebeb;
    border-radius: 100px;

    margin-right: 15px;
  }
}

.colors {
  &__own {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .color-circles {
    padding: 8px 10px;
    border-radius: 6px;
    position: relative;

    &__inner {
      display: flex;
      justify-content: center;
      width: fit-content;
    }

    &__edit-overlay {
      backdrop-filter: blur(2px);
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.015);
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .anticon {
        font-size: 1.5em;
        padding: 0 0.25em;
        cursor: pointer;

        &,
        &:hover {
          transition: ease opacity 0.5s;
        }

        &:hover {
          opacity: 0.6;
        }
      }
    }

    &:hover {
      background-color: $light-grey-medium;

      .color-circles__edit-overlay + .color-circles__inner .color__circle {
        opacity: 0.4;
      }

      .color-circles__edit-overlay {
        display: flex;
      }
    }

    .color__circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-left: -8px;
      box-shadow: $circle-box-shadow;
      border: 1px solid #ebebeb;

      &:first-child {
        margin-left: 0;
      }
    }
    &.current-theme {
      cursor: pointer;
      max-width: 365px;

      .color__circle {
        width: 50px;
        height: 50px;
      }
    }
  }

  .color-circles.selected {
    background-color: $light-grey-medium;
  }

  .color-pickers {
    .color-picker {
      padding-top: 0.5em;
    }

    .color-circle__circle {
      &,
      &:hover {
        transition: ease opacity 0.5s;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .actions {
      padding-top: 2em;
    }
    .delete-color {
      color: $red;
    }
  }
}

.color-picker {
  &__popover,
  &__cover {
    position: fixed;
  }

  &__hint {
    font-size: 0.95em;
    line-height: 1.35em;

    .anticon {
      color: $secondary-color;
      margin-right: 0.25em;
    }
  }

  &__popover {
    z-index: 50;
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgb(0 0 0 / 10%);
    border-radius: $default-border-radius;
    border: 1px solid $form-border-grey;
    padding: 10px 12px;
    max-width: 244px;
  }

  &__cover {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
  }

  &__overlay {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 80px;
    z-index: 11;
    width: 435px;
    position: fixed;
    background: #00000025;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .color-picker-popup {
    position: relative;
    padding: 10px 12px;
    z-index: 50000000000;
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgb(0 0 0 / 10%);
    border-radius: $default-border-radius;
    border: 1px solid $form-border-grey;
    width: min-content;
    bottom: 10px;
  }

  &__button {
    width: 100%;
    height: 30px;
    border: 1px solid $form-border-grey;
    border-radius: 50px !important;
    @include defaultOpacityHover(0.7);
  }

  &__preset-color {
    cursor: pointer;
    @include defaultOpacityHover(0.5);

    &__label {
      cursor: pointer;
      margin: 0.35em 0;
      font-weight: 300;
    }

    &__circle {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid #fff;
      box-shadow: 0px 1px 3px 2px rgb(0 0 0 / 10%);
      margin-left: 1em;
    }
  }

  .color-picker {
    &__no-background-styles {
      .twitter-picker,
      .sketch-picker {
        width: 220px !important;
        background: initial !important;
        border: initial !important;
        box-shadow: initial !important;
        border-radius: initial !important;
        padding: 0 !important;
      }

      #rbgcp-wrapper {
        > div:last-child {
          justify-content: space-around !important;
          > div:nth-child(1) {
            height: 40px !important;
            width: 40px !important;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
          }
          > div:nth-child(2) {
            > * {
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
              height: 20px !important;
              width: 20px !important;
            }
          }
        }
      }
    }
  }

  .twitter-picker {
    [style='padding: 15px 9px 9px 15px;'] {
      padding: 0 !important;

      & > span > div {
        border: 1px solid $form-border-grey;
        @include defaultOpacityHover(0.7);
      }
    }

    [style='background: rgb(240, 240, 240); height: 30px; width: 30px; border-radius: 4px 0px 0px 4px; float: left; color: rgb(152, 161, 164); display: flex; align-items: center; justify-content: center;'] {
      display: none !important;
    }
    input {
      display: none;
    }
  }

  .ant-btn {
    padding-left: 0;
  }
}

.agency-dashboard{
  & .color-picker__overlay{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 11;
    width: 100% !important;
    position: fixed;
    background: rgba(0, 0, 0, 0.1450980392);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
