.funnel-disable-modal {
    .support-info {
        &__label {
            display: inline-block;
            margin-right: 0.5em;
        }
    }

    .ant-select {
        margin-top: 1.5em;
    }
}
