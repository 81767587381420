.sider {
  background: linear-gradient(#1f1c1c 30%, #000000 70%) !important;

  .ant-menu-inline-collapsed {
    width: 65px !important;
  }
  .ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 27px !important;
  }
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0px 27px !important;
  }
  &-sub-menu .ant-menu-sub {
    background-color: inherit !important;
  }
  &-sub-menu {
    opacity: 0.4 !important;
    &:hover {
      opacity: 1 !important;
    }
  }

  .ui__logo {
    padding: 12px;
  }
}

.sidebar {
  &-container {
    width: 208px;
    color: white;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    margin-left: 15px;
    border-radius: 8px;
    background-image: linear-gradient(
      128.05deg,
      rgba(64, 64, 64, 0.4) 4.86%,
      rgba(130, 130, 130, 0.4) 98.42%
    );
    backdrop-filter: blur(17.6px);

    animation: slideInDiagonal 1s ease-in-out forwards;
    animation-iteration-count: 1;

    &-heading {
      color: white !important;
    }
    &-paragraph {
      margin: 0px !important;
      color: #8d8d8d !important;
      font-size: 12px;
    }
    &-progress-paragraph {
      display: flex;
      align-items: center;
      column-gap: 8px;
      margin: 0px !important;
      color: #fff !important;
      font-weight: 600;
      font-size: 14px;
      span {
        color: #8d8d8d !important;
      }
    }
    &-button {
      display: flex !important;
      align-items: center;
      column-gap: 8px;
      margin-top: 7px !important;
      color: white !important;
      width: fit-content;
      padding: 0 !important;
      cursor: pointer;
      border-bottom: 1px solid #fff !important;
      border-radius: 0 !important;

      &:hover {
        color: #40a9ff !important;
        border-color: #40a9ff !important;
      }
    }

    .ant-progress {
      .ant-progress-inner {
        .ant-progress-text {
          color: white;
        }
      }
    }
  }
  &-menu {
    background-color: inherit !important;
    width: 100%;
    border-right: 1px solid #1f1c1c !important;
    margin-top: 35px !important;

    .ant-menu-item {
      opacity: 0.4;
      height: 45px;
      line-height: 45px;
    }

    .ant-menu-item-selected::before {
      content: '';
      height: 100%;
      border-left: 4px solid $secondary-color !important;
      position: absolute;
      left: 0;
    }

    .ant-menu-item:hover {
      background-color: black !important;
      opacity: 1 !important;
    }

    .ant-menu-item-selected {
      background-color: black !important;
      color: white !important;
      opacity: 1;
    }
    .ant-menu-item::after {
      display: none;
    }
    .anticon,
    .ant-menu-submenu-arrow {
      color: white !important;
    }
    .nav-text {
      color: white !important;
    }
  }
  &-menu,
  .ant-menu-inline-collapsed {
    > .ant-menu-item .anticon {
      font-size: 16px !important;
    }
  }
}

.expanded-trophy {
  position: absolute;
  top: -21px;
  right: -10px;
  width: 85px;
  height: 100px;
  opacity: 1;
}

.collapsed-trophy {
  width: 50px;
  height: 50px;
  margin-bottom: 8px;
  margin-left: 8px;
  animation-name: slideIn;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.2) !important;
  }
}

.collapse-button-container {
  position: absolute;
  top: 49px;
  right: -12px;

  .collapse-button {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50% !important;
    background-color: white !important;
    border: 1px solid white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);

    @include defaultOpacityHover(0.9);
  }

  .collapse-button svg {
    color: #1f296d !important;
  }
}

@keyframes slideInDiagonal {
  from {
    transform: translate(-15%, 15%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
