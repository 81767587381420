/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
@media screen and (max-width: 720px) {
  @import 'MobileStyles';
}
