
.logo-settings {
  flex-direction: column;
  display: flex;
  align-items: center;

  &__preview {
    margin: 0 auto 0.65em !important;
    display: flex;
    align-items: center;
    max-height: 64px;
    max-width: 200px;
  }

  img {
    display: block !important;
    margin: 0 auto;
    max-height: 64px;
    max-width: 200px;
    margin-bottom: 15px;
  }
  .text {
    margin-top: 1em;
  }
}
