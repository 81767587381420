
.options-picker {
  &.ant-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .ant-col-8 {
    padding: 5px;
  }
}