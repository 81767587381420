.checkcircle {
    position: relative;
    font-family: sans-serif;
    font-size: 22px;
    user-select: none;
    cursor: pointer;

    &__input {
        display: none;
    }

    &__check-mark {
        width: 30px;
        height: 30px;
        background-color: #f9f9f9;
        position: absolute;
        left: 0;
        display: inline-block;
        top: 0;
        border-radius: 50%;
        border: 1px solid $form-border-grey;
    }

    &__input:checked + &__check-mark {
        background-color: $secondary-color;
        transition: 0.1s;
        border: 1px solid $secondary-color;
    }

    &__input:checked + &__check-mark:after {
        content: "";
        position: absolute;
        width: 10px;
        transition: 0.1s;
        height: 5px;
        background: $secondary-color;
        top: 45%;
        left: 50%;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}
