.choice-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.choice-card {
  display: flex;

  &,
  &:active {
    transition: ease transform 0.25s;
  }

  &:active {
    transform: scale(0.925) !important;
  }

  &__checkbox {
    display: inline-block;
    font-size: 0.35em;
    vertical-align: middle;
    margin-right: 0.75em;
    width: 16px;

    @include breakpoint(max_height_600) {
      font-size: 0.5em;
    }
  }

  &__inner {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5em 1em;
    width: 100%;
    border-radius: 5px;
    box-shadow: $primary-box-shadow;

    @include breakpoint(max_370) {
      padding: 1.15em 1em;
    }

    &.with-image {
      padding: 0;
      overflow: hidden;
      justify-content: unset;

      img {
        margin-top: -2px;
        height: 120px;
        max-height: 100%;
        object-fit: cover;

        @media (max-height: 640px) {
          height: 105px;
        }
      }

      label {
        padding: 0.5em 0.5em;
        height: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-0.15em);
      }
    }

    label {
      color: $secondary-text-color;
      margin-bottom: 0 !important;
      line-height: 1.25em;
      word-break: break-word;

      @media (max-width: 375px) and (max-height: 600px) {
        font-size: 0.9em;
      }
    }

    i,
    .icon-provider__emoji {
      font-size: 3em;
      color: #c5c5c5;
      margin-bottom: 0.175em;
      line-height: 0;

      @include breakpoint(max_height_600) {
        font-size: 2.175em;
      }
    }

    .icon-provider__emoji {
      line-height: 1;

      @include breakpoint(max_370) {
        font-size: 2em;
        margin-bottom: 0.375em;
      }
    }

    > .icon-provider {
      height: 55px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;

      @include breakpoint(max_height_600) {
        height: 38px;
      }
    }

    img {
      width: 100%;
    }

    &.active {
      i {
        color: $primary-color;
      }
    }
  }
}
