.builder__settings-sidebar {
  &__container {
    .ant-tag {
      margin-bottom: 8px;
    }

    .settings-title {
      svg {
        vertical-align: middle;
      }

      .anticon {
        color: $primary-color;
        font-size: 1.25em;

        @include defaultOpacityHover(0.6);
      }
    }
    .helper-content {
      font-size: 12px;
      color: #ccc;
    }
    .footer-setting-card {
      .builder-settings-switch {
        padding: 15px;
      }
    }
  }

  &__row {
  }

  .general-settings-tabs .ant-tabs-bar {
    padding-left: 1.5em;
  }
}