
.contact-component {
  border: 0px;
  border-radius: 100em;
  padding: 1em 2em;
  box-shadow: 0px 5px 8px rgb(0 0 0 / 20%);
  margin: 0.65rem 0;

  &__wrapper {
    padding-top: 30;
    display: flex;
    align-items: center;

    .remove-margin-from-rich-text {
      .actions-controller__wrapper {
        margin: 0px !important;
      }
    }

    .img-element {
      width: 200px;
      border: 5px solid #123abc !important;
      background-color: aqua;
      max-height: 200px !important;

      &__wrapper {
        padding-top: 0;

        .img-rounded {
          margin-right: 1em;
          object-fit: cover;
          width: 60px !important;
          height: 60px !important;
          border-radius: 50%;
        }
      }
    }

    p {
      margin-bottom: 0px;
    }
  }

  &:focus {
    border: 0px;
    box-shadow: none;
  }

  [contenteditable='true']:empty:before {
    content: attr(placeholder);
  }

  @include defaultOpacityHover;
}

.support-card-component {
  .card {
    border: none !important;
  }
}