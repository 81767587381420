.tab-container-style {
  overflow: auto !important;
  height: 100%;
  .icon-btn {
    margin: 0;
    line-height: 0;
    font-size: 1.35em;
  }
  .ant-tabs-nav-wrap {
    padding: 0 15px;
  }
  div img {
    cursor: pointer;
    margin-bottom: 10px;
  }
  .single-tab-container div:last-child img {
    margin-bottom: 0px;
  }
  .scroll-y {
    overflow-y: scroll;
  }
}

.element-card {
  width: 100%;
  text-align: center;
  color: $primary-color;
  cursor: pointer;

  &__text {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  &__icon {
    font-size: 36px;
    color: $primary-color;
  }

  &__wrapper {
    padding: 10px 10px 12px 20px;

    .ant-col-12 {
      &:nth-child(odd),
      &:nth-child(even) {
        padding: 0.5em !important;
      }

      &:nth-child(odd) {
        padding-left: 0 !important;
      }

      &:nth-child(even) {
        padding-right: 0 !important;
      }
    }
  }

  &__disabled {
    opacity: 0.5;
    .element-card{
      cursor: not-allowed !important;
    }
  }
  &__disabled:active {
    pointer-events: none;
  }

  &.ant-card {
    background: rgba(251, 253, 254, 0.9);
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 5%);

    &,
    &:hover {
      transition: all ease 0.5s;
    }

    &:hover {
      transform: translate(-2px, -2px);
      box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 5%);
    }

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &__check-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid $light-border-grey;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &.checked {
    .element-card__check-circle {
      background-color: $primary-color;
      border-color: $primary-color;
      background-image: url('/dashboard/funnel-builder/tick.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px 16px;
    }
  }

  &.small {
    .element-card {
      &__icon {
        height: 26px;
      }

      &__text {
        margin-top: 5px !important;
      }

      .ant-card-body {
        padding: 20px !important;
      }
    }
  }
}