.settings-title {
  font-size: 0.97em;
  font-weight: 400;

  .text-light {
    color: $secondary-text-color !important;
  }

  svg {
    color: $primary-color;
  }

  .anticon {
    svg {
      color: $primary-color;
    }

    &-delete {
      svg {
        color: $red !important;
      }
    }

    cursor: pointer;

    @include defaultOpacityHover(0.6);
  }

  &__icon {
    margin-right: 0.5em;
  }

  &__right {
    margin-left: auto;
    display: flex;
    align-items: center;
    .delete-confirmation-text {
      color: $red;
    }
    .anticon {
      svg {
        color: $primary-color;
      }
      &-delete {
        svg {
          color: $red !important;
        }
      }
    }
  }

  &__required:after {
    margin-left: 4px;
    color: $red;
    font-size: 14px;
    content: "*";
  }
}

.template-tags {
  padding-block: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  width: 100%;
  .ant-tag {
    background-color: $default-grey;
  }
  .ant-tag-checkable-checked {
    background-color: $primary-color;
  }
}

.ellipsis-title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  width: 90%;
  margin-bottom: 0px !important;
}
