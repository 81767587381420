.job-options-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.job-options {
  border-radius: 10px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 15px 10px;
  background-color: $light-grey;
  color: $primary-color;
  text-align: center;
  font-weight: bold;
  border: 2px solid $light-border-grey;

  .category-icon {
    font-size: 1.2rem;
  }

  @include defaultOpacityHover(0.75);
}

.selected-category {
  border: 2px solid $primary-color;
  background-color: $primary-color;
  color: $color-high-contrast;
}

.input-search {
  padding: 10px;
  border-radius: 0.5rem;
}

.search-icon {
  color: $form-border-grey;
  padding-right: 0.5rem;
}
