.banner-top-container {
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  padding: 0px !important;
  margin-bottom: 10px;
  background: $light-grey;
  border: 1px solid $light-border-grey;
  min-height: 330px;

  .text-side,
  .image-side {
    padding: 0px !important;
  }
  .text-area {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 60px;
    width: inherit;

    @include breakpoint(max_md) {
      text-align: center;
      padding: 30px 30px 0 30px;
      align-items: center;

      .text-heading {
        font-size: 26px;
      }
    }
  }
  .text-heading {
    max-width: 560px;
  }
  .banner-text-paragraph {
    max-width: 500px;
    width: 100%;
  }

  .image-side {
    display: flex;
    justify-content: right;

    img {
      object-fit: cover;
      max-height: 390px;
      max-width: 100%;
    }

    @include breakpoint(max_md) {
      justify-content: center;
    }
  }
}

.card-with-hover {
  border-radius: 8px;
  margin: 14px 0px;
  transition: all 0.3s;
  transform: translateY(0) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.1) !important;
    transform: translateY(-5px) !important;
  }

  .card-container {
    width: 100%;
    transform: translateY(0);
    min-height: 150px;

    .card-title {
      text-align: left;
      font-weight: 600;
    }

    .card-header {
      display: flex;
      align-items: center;

      &.google-status {
        margin-left: 20px;

        .anticon-plus {
          color: $secondary-color;
          @include defaultOpacityHover;
        }

        &__connected {
          justify-content: flex-end;
        }

        &__disconnected {
          justify-content: space-between;
        }
      }
    }

    .anticon {
      color: $secondary-color;
      @include defaultOpacityHover;
    }
  }

  .ant-card .ant-card-cover {
    display: flex !important;
    justify-content: flex-end !important;
    height: 70px;
    align-items: center;

    & img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 20px 20px 0px 25px !important;
    }
  }

  .ant-card .ant-card-body {
    .ant-card-meta-title {
      font-weight: 600;
    }
  }

  &__logo-not-rounded {
    .ant-card .ant-card-cover {
      & img {
        border-radius: 0;
        height: initial;
      }
    }
  }
}
