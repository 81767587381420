.statement-options {
  gap: 22px;
}

.loader-container {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ad-type-cards-container {
  display: flex;
  gap: 17px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ad-type-card {
  width: calc(25% - 17px);
  display: flex;
  border: 2px solid #efefef;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 10px 10px rgb(49 49 49 / 10%);
  margin-bottom: 15px;
}

.card-img {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.ad-type-card-content {
  padding: 20px 25px;
  height: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.ad-type-card-button {
  width: 100%;
  border-radius: 10px !important;
}

@media (max-width: 1200px) {
  .ad-type-card {
    width: calc(33.33% - 17px);
  }
}

@media (max-width: 768px) {
  .ad-type-card {
    width: calc(50% - 17px);
  }
}

@media (max-width: 480px) {
  .ad-type-card {
    width: 100%;
  }
}

.ad-type-card.selected {
  border: 2px solid #84c4ff !important;
  box-shadow: 0 6px 12px rgba(24, 143, 255, 30%);
}

.ai-generated-ad {
  position: relative;

  .settings-missing-overlay {
    position: absolute !important;
    height: 100% !important;
    width: 100% !important;
    background: rgba(255, 255, 255, 0.8);
    z-index: 100 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);

    img {
      width: 10%;
      margin-bottom: 15px;
    }
    h1 {
      margin-bottom: 0.25em;
    }
    h3 {
      color: rgb(0 0 0 / 50%) !important;
    }
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: linear-gradient(127.8deg, #04fd4a 26.72%, #02972c 87.04%);
    border: none;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #04fd4a;
    .ant-steps-icon {
      color: #04fd4a;
    }
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #04fd4a;
  }
}
