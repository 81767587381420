.ant-tabs {
  overflow-x: hidden !important;
}

.logo-header {
  display: flex;

  .ui__logo {
    padding: 0;
  }

  .logo-side {
    font-size: 1.25em;
    font-weight: 400;
    margin-left: 1em;
  }
}

.view-changer {
  display: flex;

  .preview-size-icon {
    font-size: 20px;

    &.active {
      color: $primary-color;
      font-size: 22px;
      transition: font-size 0.1s;
    }
  }
}

.header-view-changer {
  display: flex;
  gap: 10px;
  height: 100%;

  .preview-size-icon {
    font-size: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b5bad8;
    cursor: pointer;

    &.active {
      color: $primary-color;
      transition: font-size 0.1s;
      background: #e6e6e699;
      cursor: auto;
      width: 35px;
    }
  }
}

.ad-builder-header,
.empty-page-header {
  border-bottom: 1px solid rgb(227, 227, 227) !important;
  position: fixed !important;
  right: 0px !important;
  z-index: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
}

.ad-builder-header {
  left: 515px !important;
}

.empty-page-header {
  left: 80px !important;
}

.ant-switch {
  border-radius: 20px;
}

.builder {
  &__header {
    background-color: white;
    justify-content: space-between;
    box-shadow: initial;
    border-bottom: 1px solid $default-grey;
  }

  &__content {
    margin-top: 64px;
    width: 100%;
    display: block;

    .preview-section {
      width: 100%;
      background-color: white;

      &.mobile-view {
        width: 50%;
      }

      transition: width 0.2s ease-in;
    }

    .error-message {
      color: #ff4d4f;
      font-size: 14px;
      line-height: 1.5715;
    }

    .invalid-border {
      border: 1px solid #ff4d4f;
      background-color: aqua;
    }

    .active-container {
      border: 1px solid $primary-color;
      position: relative;
    }

    .actions-controller__wrapper {
      // border: 1px solid $primary-color;
      position: relative;
    }
    .active-bouncing-arrow-inside-action-controller {
      border-bottom: 55px solid white;
    }
    .actions-controller__wrapper:hover:not(.not-active-actions-controller__wrapper),
    .active-actions-controller__wrapper {
      box-shadow: inset 0 0 0 2px #ddd;
      box-sizing: border-box;
    }

    .column-container {
      text-align: center;
      .icon-container {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: $primary-color;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        cursor: pointer;
        .container-plus-icon {
          color: #fff;
          font-size: 16px;
          margin-top: 11px;
        }
      }
    }
    .append-container-icon {
      display: none !important;
    }
    .circular-plus-button {
      width: 28px;
      height: 28px;
      border-radius: 20px;
      text-align: center;
      margin: 0 auto;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      z-index: 1;

      &,
      &:hover {
        transition: transform ease 0.5s;
      }

      &:hover {
        transform: scale(1.2);
      }

      svg {
        color: #fff;
        font-size: 12px;
      }
    }
    .action-icons {
      display: none;
      position: absolute;
      z-index: 99;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        cursor: pointer;

        box-shadow: 0px -3px 8px rgb(0 0 0 / 8%);
        border-radius: 10px 10px 0 0;
        border: 1px solid $default-grey;

        li:hover {
          background-color: #fff;
        }

        li {
          width: 30px;
          height: 30px;
          text-align: center;
          display: inline-block;
          overflow: hidden;
          background-color: $light-grey;
          color: $primary-color;
          padding-top: 4px;
          border-right: 1px solid $default-grey;

          svg {
            vertical-align: middle;
          }

          &:first-child {
            border-top-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-right: 0;
          }

          &.trash-icon {
            color: $light-grey;
            background-color: $dark-orange;

            &:hover {
              background-color: $orange;
            }
          }

          &.setting-icon {
            color: $primary-color !important;
            background-color: $light-grey !important;
          }

          &.setting-icon:hover {
            background-color: #fff !important;
          }
        }
      }
    }
    .actions-controller__wrapper:hover:not(.not-active-actions-controller__wrapper) > .action-icons,
    .active-actions-controller__wrapper > .action-icons {
      display: flex !important;
    }

    .actions-controller__wrapper:hover:not(.not-active-actions-controller__wrapper)
      > .append-container-icon,
    .active-actions-controller__wrapper > .append-container-icon {
      display: flex !important;
    }

    .action-position {
      &-bottom {
        right: 5px;
        top: 0;
      }

      &-top {
        right: 0;
        top: -31px;
      }
    }
  }

  &__settings-sidebar,
  &__icon-sidebar {
    border-right: 1px solid $default-grey;
    margin-top: 64px;
  }

  &__icon-sidebar {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;

    .sidebar-icons {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      &__icon {
        height: 1.75em;
        margin: 0.75em 0.25em;
        opacity: 0.3;

        svg {
          width: 100%;
          height: 100%;
        }

        &,
        &:hover {
          transition: ease opacity 0.5s;
        }

        &:hover,
        &-active {
          opacity: 1;
        }
      }
    }
  }

  &__settings-sidebar {
    flex: 0 0 500px !important;
    max-width: 435px !important;
    min-width: 435px !important;
    width: 435px !important;
    box-shadow: initial !important;

    .menu-item {
      width: 100%;
      height: 55px;
      padding: 15px;
      display: flex;

      justify-content: space-between;

      font-size: 1.2em;
      font-weight: 200;

      border-bottom: 1px solid $default-grey;

      .anticon-right {
        color: $secondary-text-color;
      }
    }
  }
}

.ui__top-layer .ui__content {
  padding: 0;
  margin-top: 80px;
}

.block-col-style {
  background-color: $primary-color;
  height: 40px;
  border-radius: 5px;
  margin: 10px 2px;
}

.padding-left-10 {
  padding-left: 10px;
}
