.customer-login--wrapper {
  background-color: red;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-card {
    max-width: 620px;
    padding: 65px 90px;
    width: 100%;
    box-shadow: 0px 0px 100px rgb(0 0 0 / 25%);

    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .agency-logo {
        height: 55px;
        margin-bottom: 50px;
      }
    }
  }

  .ant-alert {
    margin-top: 0 !important;
  }

  .form {
    &--wrapper {
      width: 100%;

      .ant-input-prefix {
        margin-right: 8px;
      }

      .ant-btn-primary {
        color: #fff !important;

        &,
        &:hover {
          transition: ease opacity 0.5s;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      .ant-input-affix-wrapper {
        border: 1px solid #efefef;
      }
    }
  }
}

@include breakpoint(max_md) {
  .customer-login--wrapper {
    padding: 20px;

    .ant-card {
      padding: 15px 10px;
      
      .ant-card-body {
        .agency-logo {
          margin-bottom: 30px;
        }
      }
    }
  }
}
