.booking-dummy {
    border: none;
    width: 100%;
    height: 100%;

    &__wrapper {
        position: relative;
        overflow: hidden;
        min-width: 340px;
        height: 750px;
        border-radius: 10px;
        box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
        min-width: 340px;
    }

    &__loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: #fff;
        z-index: 99;
    }
}

.testimonial-video-modal {
    height: initial !important;
    background: initial !important;
    border: initial !important;
    
    iframe {
        min-height: 580px;
    }

    [class*="ModalContent__content"] {
        margin: 0 !important;
    }

    &__close {
        top: -50px !important;
        right: 0 !important;
        font-size: 2em !important;
        color: #fff !important;
    }
}
