.ai-token-rebuy-modal {
  .modal-title {
    text-align: center;
    margin: 50px 0;
  }

  .ant-list-split .ant-list-item {
    border-bottom: none;
    font-size: 12px;
    padding: 5px 0;
  }

  .ant-card-head {
    border: none;
  }

  .ant-progress-text {
    opacity: 0;
  }

  .purchase-card {
    border: 1px solid #75dbdb;
  }
}
