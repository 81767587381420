.indicator-container {
  &:first-child {
    border-radius: $default-border-radius $default-border-radius 0 0;
  }

  &:last-child {
    border-radius: 0 0 $default-border-radius $default-border-radius;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    cursor: pointer;
    border-radius: 10px 10px 0 0;

    box-shadow: 0px -3px 8px rgb(0 0 0 / 8%);

    li:hover {
      background-color: #fff;
    }

    li {
      width: 30px;
      height: 30px;
      text-align: center;
      display: inline-block;
      overflow: hidden;
      background-color: $light-grey;
      color: $primary-color;
      padding-top: 4px;
      border-right: 1px solid $default-grey;

      svg {
        vertical-align: middle;
      }

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-right: 0;
      }

      &.trash-icon {
        color: $light-grey;
        background-color: $dark-orange;

        &:hover {
          background-color: $orange;
        }
      }
      &.save-icon {
        color: white;
        background-color: $primary-color;

        &:hover {
          @include defaultOpacityHover(0.8);
        }
      }

      &.setting-icon {
        color: $primary-color !important;
        background-color: $light-grey !important;
      }

      &.drag-icon {
        cursor: move;
      }

      &.setting-icon:hover {
        background-color: #fff !important;
      }
    }
  }
}
