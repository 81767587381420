.meetovo-widget-builder-layout {
  &.mobile-view {
    @import './MobileStyles.scss';

    // Please put only styles here which should only apply within the builder the rest need to be in mobile styles on widget and dashboard project
    .widget-header {
      padding: 1rem;
  
      &-logo {
        height: 45px;
      }
  
      &-burger-icon {
        height: 30px;
        display: block;
      }
  
      &-nav-logo {
        display: none;
      }
    }
  }
}
