.infinite-scroll-component {
    overflow-x: hidden !important;
}

.icon-picker-grid {
    &__card {
        position: relative;
        padding-bottom: 56.2%;
        background-color: $light-grey-medium;
        cursor: pointer;

        &,
        &:hover {
          transition: 0.5s background-color;
        }
      
        &:hover {
            background-color: $light-grey;
        }

        &__icon {
            position: absolute;
            z-index: 10;
            opacity: 0.75;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $secondary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50%;
            width: 50%;
            background: $primary-gradient;
        }

        &.active {
            background: $primary-gradient;
        }

        &.active .icon-picker-grid__card__icon {
            background: #fff;
            opacity: 1;
        }

        img {
            position: absolute;
            width: 50%;
            height: 50%;
        }

        &.active {
            animation-name: initial;

            img {
                opacity: 0.2;
            }
        }
    }
}
