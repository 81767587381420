.template-selector {
    .ant-tabs-bar {
        margin-bottom: 30px;
    }

    .ant-tabs-tabpane {
        padding: 8px;
    }
}

.template-preview {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    &__content {
        padding: 50px 50px 0 0;
    }

    img {
        border-radius: 10px 0 0 10px;
    }

    .ant-btn-link {
        padding-left: 0;
        padding-right: 0;
    }
}
