.image-cropper-v3 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300;
  max-height: 200;
}
.image-cropper-v3__settings-wrapper {
  margin-bottom: 15px;
  .size-selector,
  .shape-selector {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 80px;
      width: 100%;
    }
  }
  .shape-selector {
    & > div {
      justify-content: start;
    }
  }
  .image-cropper-v3__settings-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $form-border-grey;
    border-radius: 3px;
    background: initial;

    &.circle {
      border-radius: 50%;
      height: 60px;
      width: 60px;
    }
    &.rect {
      height: 60px;
      width: 100px;
      margin-left: 10px;
    }

    &-free-scale {
      padding: 5px 10px;
    }

    cursor: pointer;
    padding: 5px;
    fill: #000000;
    stroke: #000000;
    &:hover {
      transform: scale(1.03);
    }
  }
  .active {
    color: $primary-color;
    border-color: $primary-color;
    box-shadow: 3px 5px 10px 3px rgb(0 0 0 / 20%);
  }
}

.image-upload-v3 {
  .ant-upload-picture-card-wrapper {
    display: block;
    width: 100%;
  }

  .image-upload__open-library {
    width: 100%;
    border-radius: 0 0 8px 8px;
    margin: 0;
  }

  .ant-upload-list-item {
    border-radius: 8px 8px 0 0 !important;
  }

  .ant-upload.ant-upload-select-picture-card, .ant-upload-list-item {
    border-bottom: 0 !important;
  }

  .ant-upload-list-item-thumbnail {
    padding-bottom: 0;
  }

  .ant-upload-list,
  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container,
  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload-picture-card-wrapper {
    border-radius: 8px 8px 0 0 !important;
  }
}

.image-upload-v4 {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  border: 2px dotted transparent;
  border-radius: 5px;
  transition: border-color 0.3s ease;

  &[data-fix-width="true"] {
    background-repeat: no-repeat;
    background-position: center;
    max-height: 300px;
    max-width: 100%;

    &[data-fits-container="true"] {
      background-size: auto;
    }

    &[data-wider-ratio="true"] {
      background-size: 90% auto;
    }

    &[data-taller-ratio="true"] {
      background-size: auto 90%;
    }
  }

  &[data-fix-width="false"] {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    max-height: 300px;
    max-width: 100%;
  }


  &__width-left {
    width: 47% !important;
    padding-right: 3px;
  }

  &__width-right {
    width: 53% !important;
    padding-left: 3px;
  }

  &:hover {
    border-color: #1890ff;

    .image-upload-v4__trash_icon {
      display: block !important;
    }
  }

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: flex;
    .image-upload-v4__archive_button__inner__icon {
      color: white;
    }
    .image-upload-v4__text {
      color: white;
    }
    .image-upload-v4__archive_button__inner__icon {
      color: white !important;
    }
  }

  &__overlay-hover:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__trash_icon {
    display: none !important;
    position: absolute;
    top: 10px;
    color: red !important;
    right: 10px;
    z-index: 100;
  }

  &__button_wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__text {
    margin-bottom: 0px !important;
    color: #bdbcbc;
  }

  &__archive_button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding-right: 7px;

      &__icon {
        color: #bdbcbc !important;
        font-size: 20px;
      }
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    margin-bottom: 0px;
    margin-right: 0px;
    border: none;
    width: 100%;
    height: 100%;
    background-color: unset;

    > span.ant-upload {
      text-align: left;
      padding: 0px !important;
    }

    > * div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: fit-content;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 20%;
    background-color: white;
    border: 1px solid #ccc;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }

    .icon {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
